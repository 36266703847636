/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    getOccurrences: { method: 'get', url: 'api/secure/student/occurrence/academic_life/{academic_life_id}'},
    postOccurrences: { method: 'post', url: 'api/secure/student/occurrence'},
    putOccurrences: { method: 'put', url: 'api/secure/student/occurrence'},
    delOccurrences: { method: 'delete', url: 'api/secure/student/occurrence/{id}'},
}