/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar das trancamentos
export const ActionGetCourseBreak = (context, payload) => {
    return services.courseBreak.getCourseBreak({academic_life_id: payload})
        .then(res => {
            if (res.status === 200) {
                const courseBreak = res.data.data;
                context.dispatch('ActionSetCourseBreak', courseBreak);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar os trancamentos`, error)
        });
}

// seta as convalidações no state da aplicação
export const ActionSetCourseBreak = ({ commit }, payload) => {
  commit(types.SET_COURSE_BREAK, payload);
}