/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar das ocorrências
export const ActionGetOccurrences = (context, payload) => {
    return services.occurrences.getOccurrences({academic_life_id: payload})
        .then(res => {
            if (res.status === 200) {
                const occurrences = res.data;
                context.dispatch('ActionSetOccurrences', occurrences);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as ocorrências da vida acadêmica`, error)
        });
}


export const ActionPostOccurrences = (context, payload) => {
    return services.occurrences.postOccurrences(payload)
        .then(res => {
            if (res.status === 201) {
                context.dispatch('ActionGetOccurrences', res.data.academicLife)
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as ocorrências da vida acadêmica`, error)
        });
}

export const ActionPutOccurrences = (context, payload) => {
    return services.occurrences.putOccurrences(payload.body)
        .then(res => {
            if (res.status === 200) {
                context.dispatch('ActionGetOccurrences', payload.academicLifeId)
            }
        })
        .catch(error =>{
            console.error(`Erro ao atualizar a ocorrência`, error)
        });
}

export const ActionDelOccurrence = (context, payload) => {
    return services.occurrences.delOccurrences({id: payload.id})
        .then(res => {
            if(res.status == 200)
                context.dispatch("ActionGetOccurrences", payload.academicLifeId);
        })
        .catch(error =>{
            console.error('Erro ao apagar a ocorrência.', error);
        });
}



// seta as ocorrências no state da aplicação
export const ActionSetOccurrences = ({ commit }, payload) => {
  commit(types.SET_OCCURRENCES, payload);
}