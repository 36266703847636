/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// Realiza a busca de todos os arquivos referentes ao usernane
export const ActionGetFiles = (context, payload) => {
  return services.file.getFilesByUsername({ username: payload })
    .then(res => {
      if (res.status === 200) {
        const files = res.body;
        context.dispatch('ActionSetFiles', files);
      }
    })
    .catch(error => {
      console.error(`Erro ao buscar a lista de arquivos!`, error)
    });
}

// realiza o download do
export const ActionDownloadFile = (context, payload) => {
  return services.file.downloadFile({ id: payload });
}

// realiza o upload de arquivos
export const ActionUploadFiles = (context, payload) => {
  return services.file.postFiles(payload)
    .then(res => {
      if (res.status === 201) {
        const file = res.body;
        context.dispatch('ActionAddFiles', file);
      }
    })
    .catch(error => {
      console.error(`Erro ao salvar arquivo!`, error)
    });
}


// realiza o upload de um arquivo
export const ActionUploadFile = (context, payload) => {
  return services.file.postFile(payload)
    .then(res => {
      if (res.status === 201) {
        const file = res.body;
        context.dispatch('ActionAddFiles', file);
      }
    });
}

// realiza o remoção de arquivo
export const ActionDeleteFile = (context, payload) => {
  return services.file.deleteFile({ id: payload })
    .then(res => {
      if (res.status === 200) {
        context.dispatch('ActionRemoveFile', payload);
      }
    })
    .catch(error => {
      console.error(`Erro ao remover o arquivo!`, error)
    });
}

// seta as arquivos no state da aplicação
export const ActionSetFiles = ({ commit }, payload) => {
  commit(types.SET_FILES, payload);
}

// adiciona o arquivo no state da aplicação
export const ActionAddFiles = ({ commit }, payload) => {
  commit(types.ADD_FILE, payload);
}

// remove o arquivo no state da aplicação
export const ActionRemoveFile = ({ commit }, payload) => {
  commit(types.REMOVE_FILE, payload);
}