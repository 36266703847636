/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default



// realiza a alteração do curriculo lattes do estudante
export const ActionPutStudentLattes = (context, payload) => {
    return services.lattes.putLattes({academic_code: payload.academic_code}, payload.body); // primeiro parametro, objeto com as variáveis, segundo parametro corpo da requisição
}
