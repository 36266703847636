/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    getCourses: { method: 'get', url: 'api/secure/student/course' },
    putCourses: { method: 'put', url: 'api/secure/student/course' },
    postCourses: { method: 'post', url: 'api/secure/student/course' },
    getCourseById: { method: 'get', url: 'api/secure/student/course/{id}' },
    delCourseById: { method: 'delete', url: 'api/secure/student/course/{id}' },
    getCourseByCode: { method: 'get', url: 'api/secure/student/course/code/{code}' },
    getCoursesByCollegeCode: { method: 'get', url: 'api/secure/student/course/college/{code}' },
}