/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar as atualizações
export const ActionGetDatabaseUpdate = (context) => {
    return services.databaseUpdate.getDatabaseUpdate()
        .then(res => {
            if (res.status === 200) {
                const databaseUpdate = res.body;
                context.dispatch('ActionSetDatabaseUpdate', databaseUpdate);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar atualizações do sistema`, error)
        });
}


// realiza a buscar as atualizações
export const ActionPostDatabaseUpdate = async () => {
    return await services.databaseUpdate.setDatabaseUpdate();
}

// seta as atualizações no state da aplicação
export const ActionSetDatabaseUpdate = ({ commit }, payload) => {
  commit(types.SET_DATABASE_UPDATE, payload);
}