/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    getScholarshipQuota: { method: 'get', url: 'api/secure/student/scholarship_quota' },
    putScholarshipQuota: { method: 'put', url: 'api/secure/student/scholarship_quota' },
    postScholarshipQuota: { method: 'post', url: 'api/secure/student/scholarship_quota' },
    delScholarshipQuota: { method: 'delete', url: 'api/secure/student/scholarship_quota/{id}' },
    getScholarshipQuotaById: { method: 'get', url: 'api/secure/student/scholarship_quota/{id}' },
    putStatusScholarshipQuota: { method: 'put', url: 'api/secure/student/scholarship_quota/status/{id}' },
    getScholarshipQuotaByCourse: { method: 'get', url: 'api/secure/student/scholarship_quota/course/{code}' },
    postScholarshipQuotaByQuantity: { method: 'post', url: 'api/secure/student/scholarship_quota/{quantity}' },
    getScholarshipQuotaByFundingAgency: { method: 'get', url: 'api/secure/student/scholarship_quota/funding_agency/{id}' },
}