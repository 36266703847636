/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

 export default {
    getEnrollmentPeriod: { method: 'get', url: 'api/secure/common/enrollment_period' },
    putEnrollmentPeriod: { method: 'put', url: 'api/secure/common/enrollment_period' },
    postEnrollmentPeriod: { method: 'post', url: 'api/secure/common/enrollment_period' },
    getEnrollmentPeriodByID: { method: 'get', url: 'api/secure/common/enrollment_period/{id}' },
    delEnrollmentPeriodByID: { method: 'delete', url: 'api/secure/common/enrollment_period/{id}' },
    getCurrentEnrollmentPeriod: { method: 'get', url: 'api/secure/common/enrollment_period/current' },    
 }