/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    getOccurrenceType: { method: 'get', url: 'api/secure/student/occurrence_type' },
    getOccurrenceTypeActives: { method: 'get', url: 'api/secure/student/occurrence_type/actives' },
    getOccurrenceTypes: { method: 'get', url: 'api/secure/student/occurrence_type/types' },
    postOccurrenceTypes: { method: 'post', url: 'api/secure/student/occurrence_type' },
    putOccurrenceType: { method: 'put', url: 'api/secure/student/occurrence_type' },
    delOccurrenceTypes: { method: 'delete', url: 'api/secure/student/occurrence_type/{id}' },
}