import services from '@/http'
import * as types from './mutations_types'


// realiza a buscar pelos professores do instituto
export const ActionGetProfessors = (context) => {
    return services.professors.getProfessors()
        .then(res => {
            if (res.status === 200) {
                const professors = res.data;
                context.dispatch('ActionSetProfessors', professors);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar professores`, error)
        });
}

// realiza a buscar pelos professores do instituto
export const ActionGetAllProfessors = (context) => {
    return services.professors.getAllProfessors()
        .then(res => {
            if (res.status === 200) {
                const professors = res.data;
                context.dispatch('ActionSetProfessors', professors);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar professores`, error)
        });
}

// seta as professores no state da aplicação
export const ActionSetProfessors = ({ commit }, payload) => {
  commit(types.SET_PROFESSORS, payload);
}
