/**
 *      Arquivo responsável por concentrar todas as rotas
 *          exporta como objeto para facilitar a importação nos templates pai 
 */
export default {
        path: '/estudantes',
        name: 'Students',
        meta: {
            requiresAuth: true,
            authorizedUsers: ['admin', 'staff', 'faculty']
        },
        component: () => import('./Students')
    }  
