// import { routes as PublicTechnicalReports } from '@/components/technical_report/technical_reports/index'
// import { routes as TechnicalReportsDetail } from '@/components/technical_report/technical_reports_detail/index'


export default [
    {
        path: '/relatorios_tecnicos',
        name: 'PublicTechnicalReport',
        meta: {
            requiresAuth: false
        },
        component: () => import('./PublicTechnicalReport'),
        children: [  ]
    }
]