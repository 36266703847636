/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar das convalidações
export const ActionGetEnglishProficiency = (context, payload) => {
    return services.englishProficiency.getEnglishProficiency({academic_life_id: payload})
        .then(res => {
            if (res.status === 200) {
                const subjects = res.data.data;
                context.dispatch('ActionSetEnglishProficiency', subjects);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar os exames de inglês`, error)
        });
}

// seta as convalidações no state da aplicação
export const ActionSetEnglishProficiency = ({ commit }, payload) => {
  commit(types.SET_ENGLISH_PROFICIENCY, payload);
}