/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
    path: '/docente/:academic_code',
    name: 'Professor',
    meta: {
        requiresAuth: true,
        authorizedUsers: ['admin', 'staff', 'faculty']
    },
    component: () => import('./Professor')
}
