/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    //postFile: { method: 'post', url: 'api/secure/common/sigpos_file'},
    postFile: { method: 'post', url: 'api/secure/common/sigpos_file/file'},
    postFiles: { method: 'post', url: 'api/secure/common/sigpos_file/files'},
    deleteFile: { method: 'delete', url: 'api/secure/common/sigpos_file/{id}'},
    getFilesByUsername: { method: 'get', url: 'api/secure/common/sigpos_file/username/{username}'},
    downloadFile: { method: 'get', url: 'api/secure/common/storage/file/{id}', responseType: 'arraybuffer'},
}