/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar os docentes
export const ActionGetGeneralFaculty = (context) => {
    return services.generalFaculty.getGeneralFaculty()
        .then(res => {
            if (res.status === 200) {
                const docentes = res.body;
                context.dispatch('ActionSetGeneralFaculty', docentes);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar os orientadores`, error)
        });
}

// seta as convalidações no state da aplicação
export const ActionSetGeneralFaculty = ({ commit }, payload) => {
  commit(types.SET_GENERAL_FACULTY, payload);
}