/**
 *      Arquivo responsável por centralizar todos as 
 *          store contidas nos módulos da aplicação
 */
import { store as auth } from '@/pages/auth/index'
import { store as ped } from '@/components/student/ped/index'
import { store as course } from '@/components/admin/course/index'
import { store as flags } from '@/components/student/flags/index'
import { store as lattes } from '@/components/student/lattes/index'
import { store as guidance } from '@/components/student/guidance/index'
import { store as subjects } from '@/components/student/subjects/index'
import { store as classes } from '@/components/common/classes/index'
import { store as student } from '@/components/student/student_info/index'
import { store as scholarship } from '@/components/admin/scholarship/index'
import { store as occurrences } from '@/components/student/occurrence/index'
import { store as activeUsers } from '@/components/admin/active_users/index'
import { store as generalAdmin } from '@/components/admin/admin/admin/index'
import { store as academicLife } from '@/components/admin/academicLife/index'
import { store as adminOccurrence } from '@/components/admin/occurrence/index'
import { store as courseBreak } from '@/components/student/course_break/index'
import { store as enrollments } from '@/components/common/enrollments/index'
import { store as fundingAgency } from '@/components/admin/funding_agency/index'
import { store as qualification } from '@/components/student/qualification/index'
import { store as databaseUpdate } from '@/components/admin/database_update/index'
import { store as generalFaculty } from '@/components/admin/general_faculty/index'
import { store as numberStudents } from '@/components/common/number_students/index'
import { store as occurrenceType } from '@/components/student/occurrence_type/index'
import { store as scholarshipQuota } from '@/components/admin/scholarship_quota/index'
import { store as availableResource } from '@/components/admin/available_resource/index'
import { store as englishProficiency } from '@/components/student/english_proficiency/index'
import { store as subjectValidations } from '@/components/student/subjects_validations/index'
import { store as guidances } from '@/components/faculty/guidances/index'
import { store as students } from '@/components/student/students/index'
import { store as professors } from '@/components/faculty/professors/index'
import { store as professor } from '@/components/faculty/professor/index'

import { store as file } from '@/components/common/file/index'

import { store as editors } from '@/components/technical_report/editors/index'
import { store as facultyEditor } from '@/components/faculty/editor/index'
import { store as facultyOpinion } from '@/components/faculty/faculty_opinion/index'
import { store as publicTechnicalReportsYears } from '@/pages/technical_reports/index'
import { store as technicalReports } from '@/components/technical_report/technical_reports/index'

import { store as signUpCandidate } from '@/pages/signup_candidate/index'
import { store as candidateInfo } from '@/components/candidate/profile/index'
import { store as editCandidate } from '@/components/candidate/edit_candidate/index'
import { store as enrollmentPeriod } from '@/components/candidate/enrollment_period/index'
import { store as candidateEnrollment } from '@/components/candidate/candidate_enrollment/index'
import { store as opinionGrade } from '@/components/candidate/opinion_enrollment_request_grade/index'
import { store as opinionEnrollmentRequest } from '@/components/candidate/opinion_enrollment_request/index'
import { store as opinionEnrollmentRequestGrade } from '@/components/candidate/opinion_enrollment_request_grade/index'


export default {
    ped,
    auth,
    flags,
    course,
    lattes,
    classes,
    student,
    guidance,
    subjects,
    students,
    guidances,
    professor,
    professors,
    enrollments,
    activeUsers,
    occurrences,
    courseBreak,
    scholarship,
    academicLife,
    generalAdmin,
    fundingAgency,
    qualification,
    databaseUpdate,
    numberStudents,
    occurrenceType,
    generalFaculty,
    adminOccurrence,
    scholarshipQuota,
    availableResource,
    englishProficiency,
    subjectValidations,

    file,
    
    editors,
    facultyEditor,
    technicalReports,
    publicTechnicalReportsYears,
    
    opinionGrade,
    candidateInfo,
    editCandidate,
    facultyOpinion,
    signUpCandidate,
    enrollmentPeriod,
    candidateEnrollment,
    opinionEnrollmentRequest,
    opinionEnrollmentRequestGrade,
}