/**
 *      Arquivo responsável por concentrar todas as rotas das páginas
 *          do grupo auth
 */
 export default 
    {
        path: '/solicitacoes/:username',
        name: 'EnrollmentRequest',
        meta: { 
            requiresAuth: true,
            authorizedUsers: ['admin', 'candidate', 'staff', 'faculty'] 
        },
        component: () => import('./EnrollmentRequest')
    }
