/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar das ped
export const ActionGetPed = (context, payload) => {
    return services.ped.getPed({academic_life_id: payload})
        .then(res => {
            if (res.status === 200) {
                const peds = res.data.data;
                context.dispatch('ActionSetPed', peds);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar os exames de qualificação`, error)
        });
}

// seta os ped no state da aplicação
export const ActionSetPed = ({ commit }, payload) => {
  commit(types.SET_PED, payload);
}