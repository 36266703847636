import services from '@/http' 
import * as types from './mutations_types'


export const ActionGetEditors = (context) => {
  return services.editors.getAllEditors()
    .then(res => {
      if (res.status == 200) {
        const editors = res.body;
        context.dispatch('ActionSetEditors', editors);
      }
    })
    .catch(error => {
      console.error(`Erro ao buscar a lista de editores!`, error)
      return error;
    });
}

export const ActionPostEditor = (context, payload) => {
  return services.editors.postEditor(payload)
    .then(res => {
      if(res.status === 201){
        const editor = res.body;
        context.dispatch('ActionADDEditor', editor);
      }
    })
}

export const ActionPutEditor = (context, payload) => {
  return services.editors.putEditor({id: payload.id}, payload)
    .then(res => {
      if(res.status === 200){
        const editor = res.body;
        context.dispatch('ActionUpdateEditor', editor);
      }
    })
}

export const ActionDelEditor = (context, payload) => {
  return services.editors.deleteEditor({ id: payload })
  .then(res => {
    if (res.status === 200){
      context.dispatch('ActionRemoveEditor', payload);
    }
  })
}

export const ActionSetEditors = ({ commit }, payload) => {
  commit(types.SET_EDITORS, payload);
}

export const ActionADDEditor = ({ commit }, payload) => {
  commit(types.ADD_EDITOR, payload);
}

export const ActionRemoveEditor = ({ commit }, payload) => {
  commit(types.REMOVE_EDITOR, payload);
}

export const ActionUpdateEditor = ({ commit }, payload) => {
  commit(types.UPDATE_EDITOR, payload);
}
