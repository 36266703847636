/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default

export const ActionGetOpinionByRequestId = (context, payload) => {
    return services.opinionEnrollmentRequest.getOpinionByRequest({ id: payload });
}

export const ActionPostOpinion = (context, payload) => {
    return services.opinionEnrollmentRequest.postOpinion(payload);
}