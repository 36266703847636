/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default [
    {
        path: '/editar',
        name: 'EditCandidate',
        meta: {
            requiresAuth: true,
            authorizedUsers: ['candidate']
        },
        component: () => import('./EditCandidate')
    },
    {
        path: '/editar_senha',
        name: 'CandidatePassword',
        meta: {
            requiresAuth: true,
            authorizedUsers: ['candidate']
        },
        component: () => import('./CandidatePassword')
    }
]
