/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar das orientações
export const ActionGetGuidance = (context, payload) => {
    return services.guidance.getGuidance({academic_life_id: payload})
        .then(res => {
            if (res.status === 200) {
                const guidances = res.data.data;
                context.dispatch('ActionSetGuidance', guidances);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as orientações do aluno`, error)
        });
}

// seta as convalidações no state da aplicação
export const ActionSetGuidance = ({ commit }, payload) => {
  commit(types.SET_GUIDANCE, payload);
}