/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    delAvailableResource: { method: 'delete', url: 'api/secure/student/available_resource/{id}' },
    putAvailableResource: { method: 'put', url: 'api/secure/student/available_resource' },
    getAvailableResources: { method: 'get', url: 'api/secure/student/available_resource/funding_agency/{id}' },
    postAvailableResource: { method: 'post', url: 'api/secure/student/available_resource/funding_agency/{id}' },
}