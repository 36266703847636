/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */

import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'

// realiza a buscar pelos orientandos
export const ActionPutCandidate = (context, payload) => {
  return services.editCandidate.putCandidate({ id: payload.id }, payload)
    .then(res => {
      if (res.status === 200) {
        context.dispatch('ActionSetCandidate', res.body);
      }
    });
}

// Busca o candidato a aluno especial
export const ActionGetCandidate = (context, payload) => {
  return services.editCandidate.getCandidate({ username: payload })
    .then(res => {
      if (res.status === 200) {
        context.dispatch('ActionSetCandidate', res.body);
      }
    })
    .catch(e => {
      console.error(e);
    });
}

// Atualiza o password
export const ActionPutPassword = (conext, payload) => {
  return services.editCandidate.updatePassword(payload);
}

// seta as orientações no state da aplicação
export const ActionSetCandidate = ({ commit }, payload) => {
  commit(types.SET_CANDIDATE, payload);
}

