export default {
    putTechnicalReport: { method: 'put', url: 'api/secure/technical_report'},
    postTechnicalReport: { method: 'post', url: 'api/secure/technical_report'},
    getTechnicalReportById: { method: 'get', url: 'api/secure/technical_report/{id}'},
    delTechnicalReportById: { method: 'delete', url: 'api/secure/technical_report/{id}'},
    getTechnicalReports: { method: 'get', url: 'api/secure/technical_report/all/username/{username}'},

    postTechnicalReportStatus: { method: 'post', url: 'api/secure/technical_report/status'},
    delTechnicalReportStatus: { method: 'delete', url: 'api/secure/technical_report/status/{id}'},
    getTechnicalReportStatusByTechnicalReportId: { method: 'get', url: 'api/secure/technical_report/{id}/status'},
    getPossibleStatuses: { method: 'get', url: 'api/secure/technical_report/get_possible_statuses'},

    postTechnicalReportComment: { method: 'post', url: 'api/secure/technical_report/comment'},
    delTechnicalReportCommentsById: { method: 'delete', url: 'api/secure/technical_report/comment/{id}'},
    getTechnicalReportCommentsByTechnicalReportId: { method: 'get', url: 'api/secure/technical_report/{id}/comment'},
}