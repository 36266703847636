/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    postCandidate: { method: 'post', url: 'api/candidate'},
    getColleges: { method: 'get', url: 'api/candidate/colleges' },
    getCountries: { method: 'get', url: 'api/candidate/countries' },
    getAddress: { method: 'get', url: 'api/candidate/address/{cep}' },
}