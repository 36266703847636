/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// ---------------- realiza a busca as bolsas de estudos
export const ActionGetScholarships = (context) => {
    return services.scholarship.getScholarships()
        .then(res => {
            if (res.status === 200) {
                const scholarships = res.body;
                context.dispatch('ActionSetScholarships', scholarships);
            }
        })
        .catch(error =>{
            console.error('Erro ao buscar as bolsas de estudos', error)
        });
}
// seta as bolsas de estudo no state da aplicação
export const ActionSetScholarships = ({ commit }, payload) => {
  commit(types.SET_SCHOLARSHIPS, payload);
}

// ---------------- realiza a busca de uma bolsa de estudos de acordo com o seu ID
export const ActionGetScholarshipsById = (context, payload) => {
    return services.scholarship.getScholarship({ id: payload });
}

// ---------------- realiza a busca as bolsas de estudos de acordo com a cota
export const ActionGetScholarshipsByQuotaId = (context, payload) => {
    return services.scholarship.getScholarshipsByQuotaId({ id: payload });
}

// -------------- realiza a inserção de uma bolsa de estudos
export const ActionPostScholarship = (context, payload) => {
    /*payload.begin = payload.begin;// + "T00:00:00-03:00";
    if(payload.end) payload.end = payload.end; //+ "T00:00:00-03:00";*/
    return services.scholarship.postScholarship(payload)
    .then(res => {
        if(res.status === 201){
            const scholarship = res.body;
            context.dispatch('ActionAddScholarship', scholarship);
        }
    })
    .catch(error => {
        console.error('Erro ao criar uma bolsa de estudos', error)
    })
}
// adiciona uma bolsa de estudos no state da aplicação
export const ActionAddScholarship = ({ commit }, payload) => {
    commit(types.ADD_SCHOLARSHIP, payload);
}



// -------------- realiza a atualizaçõa de uma bolsa de estudos
export const ActionPutScholarship = (context, payload) => {
   /* payload.begin = payload.begin + "T00:00:00-03:00";
    if(payload.end) payload.end = payload.end + "T00:00:00-03:00";*/
    return services.scholarship.putScholarship(payload)
    .then(res => {
        if(res.status === 200){
            const scholarship = res.body;
            context.dispatch('ActionUpdateScholarship', scholarship);
        }
    })
    .catch(error => {
        console.error('Erro ao atualizar a bolsa de estudos', error)
    })
}
// -------------- realiza a desativação de uma bolsa de estudos
export const ActionDeactivateScholarship = (context, payload) => {
    return services.scholarship.deactivateScholarship({ id: payload}, {})
    .then(res => {
        if(res.status === 200){
            const scholarship = res.body;
            context.dispatch('ActionUpdateScholarship', scholarship);
        }
    })
    .catch(error => {
        console.error('Erro ao atualizar a bolsa de estudos', error)
    });
}
// atualiza uma bolsa de estudos no state da aplicação
export const ActionUpdateScholarship = ({ commit }, payload) => {
    commit(types.UPDATE_SCHOLARSHIP, payload);
}



// -------------- realiza a remoção de uma bolsa de estudos
export const ActionDelScholarship = (context, payload) => {
    return services.scholarship.delScholarship({ id: payload })
        .then(res =>{
            if(res.status === 200){
                context.dispatch('ActionRemoveScholarship', payload);
            }
        }).catch (error => {
            console.error('Erro ao remover uma bolsa de estudos!', error);
        });
}
// atualiza uma bolsa de estudos no state da aplicação
export const ActionRemoveScholarship = ({ commit }, payload) => {
    commit(types.REMOVE_SCHOLARSHIP, payload);
}
