/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'
import * as types from './mutations_types'

// realiza a busca de notas passíveis de serem aplicadas nas solicitações de matrículas
export const ActionGetAllOpinionGrade = (context) => {
    return services.opinionGrade.getAllOpinionGrade()
        .then(res => {
            if (res.status === 200) {
                const grades = res.body;
                context.dispatch('ActionSetOpinionGrades', grades);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar as notas`, error)
        });
}


// realiza a inserção de nova nota passível de ser aplicada nas solicitações de matrículas
export const ActionPostOpinionGrade = (context, payload) => {
    return services.opinionGrade.postOpinionGrade(payload)
        .then(res => {
            if (res.status === 201) {
                const grade = res.body;
                context.dispatch('ActionADDOpinionGrade', grade);
            }
            return res;
        })
        .catch(error => {
            return error;
        });
}


// realiza a inserção de nova nota passível de ser aplicada nas solicitações de matrículas
export const ActionPutOpinionGrade = (context, payload) => {
    return services.opinionGrade.putOpinionGrade(payload)
        .then(res => {
            if (res.status === 200) {
                const grade = res.body;
                context.dispatch('ActionUpdateOpinionGrade', grade);
            }
            return res;
        })
        .catch(error => {
            return error;
        });
}


// realiza a remoção de nova nota
export const ActionDeleteOpinionGrade = (context, payload) => {
    return services.opinionGrade.delAllOpinionGrade({ id: payload })
        .then(res => {
            if (res.status === 200) {
                context.dispatch('ActionRemoveOpinionGrade', payload);
            }
            return res;
        })
        .catch(error => {
            return error;
        });
}


// realiza o set de notas no state da aplicação
export const ActionSetOpinionGrades = ({ commit }, payload) => {
    commit(types.SET_OPINION_GRADE, payload);
}

// realiza a adição de uma nota no state da aplicação
export const ActionADDOpinionGrade = ({ commit }, payload) => {
    commit(types.ADD_OPINION_GRADE, payload);
}

// realiza a atualização de uma nota no state da aplicação
export const ActionUpdateOpinionGrade = ({ commit }, payload) => {
    commit(types.UPDATE_OPINION_GRADE, payload);
}

// realiza a remoção de uma nota no state da aplicação
export const ActionRemoveOpinionGrade = ({ commit }, id) => {
    commit(types.REMOVE_OPINION_GRADE, id);
}