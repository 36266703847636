/**
 *  Arquivo reponsável por centralizar todas as requisições que serão feitas polos módulos
 */

import { services as auth } from '@/pages/auth/index'
import { services as ped } from '@/components/student/ped/index'
import { services as course } from '@/components/admin/course/index'
import { services as lattes } from '@/components/student/lattes/index'
import { services as guidance } from '@/components/student/guidance/index'
import { services as subjects } from '@/components/student/subjects/index'
import { services as classes } from '@/components/common/classes/index'
import { services as students } from '@/components/student/students/index'
import { services as student } from '@/components/student/student_info/index'
import { services as scholarship } from '@/components/admin/scholarship/index'
import { services as occurrences } from '@/components/student/occurrence/index'
import { services as activeUsers } from '@/components/admin/active_users/index'
import { services as generalAdmin } from '@/components/admin/admin/admin/index'
import { services as academicLife } from '@/components/admin/academicLife/index'
import { services as adminOccurrence } from '@/components/admin/occurrence/index'
import { services as courseBreak } from '@/components/student/course_break/index'
import { services as enrollments } from '@/components/common/enrollments/index'
import { services as fundingAgency } from '@/components/admin/funding_agency/index'
import { services as databaseUpdate } from '@/components/admin/database_update/index'
import { services as qualification } from '@/components/student/qualification/index'
import { services as generalFaculty } from '@/components/admin/general_faculty/index'
import { services as numberStudents } from '@/components/common/number_students/index'
import { services as occurrenceType } from '@/components/student/occurrence_type/index'
import { services as scholarshipQuota } from '@/components/admin/scholarship_quota/index'
import { services as availableResource } from '@/components/admin/available_resource/index'
import { services as englishProficiency } from '@/components/student/english_proficiency/index'
import { services as subjectsValidations } from '@/components/student/subjects_validations/index'
import { services as professors } from '@/components/faculty/professors/index'
import { services as professor } from '@/components/faculty/professor/index'

import { services as file } from '@/components/common/file/index'

import { services as editors } from '@/components/technical_report/editors/index'
import { services as facultyEditor } from '@/components/faculty/editor/index'
import { services as facultyOpinion } from '@/components/faculty/faculty_opinion/index'
import { services as publicTechnicalReportsYears } from '@/pages/technical_reports/index'
import { services as technicalReports } from '@/components/technical_report/technical_reports/index'

import { services as signUpCandidate } from '@/pages/signup_candidate/index'
import { services as candidateInfo } from '@/components/candidate/profile/index'
import { services as editCandidate } from '@/components/candidate/edit_candidate/index'
import { services as enrollmentPeriod } from '@/components/candidate/enrollment_period/index'
import { services as candidateEnrollment } from '@/components/candidate/candidate_enrollment/index'
import { services as opinionGrade } from '@/components/candidate/opinion_enrollment_request_grade/index'
import { services as opinionEnrollmentRequest } from '@/components/candidate/opinion_enrollment_request/index'
import { services as opinionEnrollmentRequestGrade } from '@/components/candidate/opinion_enrollment_request_grade/index'

export default {
    ped,
    auth,
    course,
    lattes,
    classes,
    student,
    guidance,
    subjects,
    students,
    professor,
    professors,
    enrollments,
    occurrences,
    activeUsers,
    courseBreak,
    scholarship,
    academicLife,
    generalAdmin,
    qualification,
    fundingAgency,
    databaseUpdate,
    numberStudents,
    occurrenceType,
    generalFaculty,
    adminOccurrence,
    scholarshipQuota,
    availableResource,
    englishProficiency,
    subjectsValidations,

    file,

    editors,
    facultyEditor,
    facultyOpinion,
    technicalReports,
    publicTechnicalReportsYears,

    opinionGrade,
    candidateInfo,
    editCandidate,
    signUpCandidate,
    enrollmentPeriod,
    candidateEnrollment,
    opinionEnrollmentRequest,
    opinionEnrollmentRequestGrade
}