/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_GENERAL_ADMIN] (state, payload){
        state.generalAdmin = payload
    },

    [types.ADD_GENERAL_ADMIN] (state, payload){        
        const exists = state.generalAdmin.find(a => a.id == payload.id);
        if(!exists)
            state.generalAdmin.push(payload);
    },

    [types.REMOVE_GENERAL_ADMIN] (state, payload){    
        const newList = state.generalAdmin.filter(a => a.id != payload.id)    
        state.generalAdmin = newList;
    },
}