import services from '@/http'
import * as types from './mutations_types'


// realiza a buscar pelos orientandos
export const ActionGetFacultyGuidance = (context) => {
    return services.professorGuidance.getfacultyGuidance()
        .then(res => {
            if (res.status === 200) {
                const guidances = res.data;
                context.dispatch('ActionSetFacultyGuidance', guidances);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar orientandos`, error)
        });
}


// realiza a buscar pelos coorientandos
export const ActionGetFacultyCoGuidance = (context) => {
    return services.professorGuidance.getfacultyCoGuidance()
        .then(res => {
            if (res.status === 200) {
                const guidances = res.data;
                context.dispatch('ActionSetFacultyCoGuidance', guidances);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar coorientandos`, error)
        });
}


// seta as orientações no state da aplicação
export const ActionSetFacultyGuidance = ({ commit }, payload) => {
  commit(types.SET_PROFESSOR_GUIDANCES, payload);
}

// seta as orientações no state da aplicação
export const ActionSetFacultyCoGuidance = ({ commit }, payload) => {
  commit(types.SET_PROFESSOR_COGUIDANCES, payload);
}