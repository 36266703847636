export const SET_TECHNICAL_REPORTS = 'TECHNICAL_REPORTS/SET_TECHNICAL_REPORTS'
export const ADD_TECHNICAL_REPORT = 'TECHNICAL_REPORTS/ADD_TECHNICAL_REPORT'
export const UPDATE_TECHNICAL_REPORT = 'TECHNICAL_REPORTS/UPDATE_TECHNICAL_REPORT'
export const DELETE_TECHNICAL_REPORT = 'TECHNICAL_REPORTS/DELETE_TECHNICAL_REPORT'

export const SET_TECHNICAL_REPORT_DETAILS = 'TECHNICAL_REPORTS/SET_TECHNICAL_REPORT_DETAILS'

export const SET_TECHNICAL_REPORT_DETAILS_COMMENTS = 'TECHNICAL_REPORTS/SET_TECHNICAL_REPORT_DETAILS_COMMENTS'
export const ADD_TECHNICAL_REPORT_DETAILS_COMMENT = 'TECHNICAL_REPORTS/ADD_TECHNICAL_REPORT_DETAILS_COMMENT'
export const REMOVE_TECHNICAL_REPORT_DETAILS_COMMENT = 'TECHNICAL_REPORTS/REMOVE_TECHNICAL_REPORT_DETAILS_COMMENT'

export const SET_TECHNICAL_REPORT_DETAILS_STATUS = 'TECHNICAL_REPORTS/SET_TECHNICAL_REPORT_DETAILS_STATUS'
export const ADD_TECHNICAL_REPORT_DETAILS_STATUS = 'TECHNICAL_REPORTS/ADD_TECHNICAL_REPORT_DETAILS_STATUS'
export const REMOVE_TECHNICAL_REPORT_DETAILS_STATUS = 'TECHNICAL_REPORTS/REMOVE_TECHNICAL_REPORT_DETAILS_STATUS'