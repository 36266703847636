
/**
 * Arquivo responsável por filtrar dados do aluno
 */

// Interpretador de status
export const studentUtils = {

  // Função para retornar a vida acadêmica ativa
  returnActiveAcademicLife: (academicLifes) => {
    let academicLife = academicLifes.filter(
      acal => acal.situation === "Ativo - Curso em Andamento"
    );
    return academicLife[0];
  },

  arrayMin: (arr) => {
    if (arr !== null) {
      return arr.reduce(function (p, v) {
        return p.codigo < v.codigo ? p : v;
      });
    } else {
      return {}
    }
  }
}