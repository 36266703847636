/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_FUNDING_AGENCIES] (state, payload){
        state.fundingAgencies = payload
    },

    [types.ADD_FUNDING_AGENCY] (state, payload){
        state.fundingAgencies.push(payload);
    },

    [types.RM_FUNDING_AGENCY] (state, payload){
        state.fundingAgencies = state.fundingAgencies.filter(e => e.id != payload);
    }
}