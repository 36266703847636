/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
	path: '/informacoes_candidato/:id',
	name: 'CandidateInfo',
	meta: {
		requiresAuth: true,
		authorizedUsers: ['faculty', 'admin'],
	},
	component: () => import('./CandidateInfo'),
};
