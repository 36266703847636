/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'
import { statusInterpreter } from '@/utils/statusInterpreter'
import { studentUtils } from '@/utils/studentUtils'


// realiza a buscar dos alunos ativos
export const ActionGetStudents = (context, payload) => {
    return services.students.getStudents(
        {
            page: payload.page,
            size: payload.size
        })
        .then(res => {
            if (res.status === 200) {
                const students = changeStudentList(res.data);
                context.dispatch('ActionSetStudents', students);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar a listagem de alunos`, error)
        });
}


// realiza a buscar dos alunos ativos
export const ActionGetActiveStudents = (context) => {
    return services.students.getActivesStudents()
        .then(res => {
            if (res.status === 200) {
                const students = changeStudentList(res.body);
                context.dispatch('ActionSetStudents', students);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar a listagem de alunos`, error)
        });
}


// Realiza a busca de alunos pelo nome
export const ActionGetStudentsByName = (context, payload) => {
    return services.students.getStudentsByName({ name: payload })
        .then(res => {
            if (res.status === 200) {
                const students = res.data;
                context.dispatch('ActionSetStudents', students);
            }
        })
        .catch(error => {
            console.error('Erro ao buscar alunos pelo nome', error);
        });
}

// seta os alunos no state da aplicação
export const ActionSetStudents = ({ commit }, payload) => {
    commit(types.SET_STUDENTS, payload);
}


function changeStudentList(list) {
    let items = [];

    if (list == [])
        return list;

    if (list !== null) {
        list.map((student) => {

            let academicLife = student.academicLife;
            let rules = student.rules;

            let englishStatus;
            let englishColor;
            let englishSpan;
            let englishCode;

            let creditsStatus;
            let creditsColor;
            let creditsSpan;
            let creditsCode;

            let orientatorStatus;
            let orientatorColor;
            let orientatorSpan;
            let orientatorCode;

            let pedStatus;
            let pedColor;
            let pedSpan;
            let pedCode;

            let crStatus;
            let crColor;
            let crSpan;
            let crCode;

            let examStatus;
            let examColor;
            let examSpan;
            let examCode;

            rules.map((rule) => {
                if (rule.mensagens !== null && rule.mensagens.length > 0) {
                    const mensagem = studentUtils.arrayMin(rule.mensagens);
                    const tipo = rule.tipo;

                    switch (tipo) {
                        case "proficiencia":
                            englishStatus = statusInterpreter.desc(mensagem.descricao);
                            englishColor = statusInterpreter.cor(mensagem.codigo);
                            englishSpan = statusInterpreter.span(mensagem.codigo);
                            englishCode = mensagem.codigo;
                            break;
                        case "creditos":
                            creditsStatus = statusInterpreter.desc(mensagem.descricao);
                            creditsColor = statusInterpreter.cor(mensagem.codigo);
                            creditsSpan = statusInterpreter.span(mensagem.codigo);
                            creditsCode = mensagem.codigo;
                            break;
                        case "orientacao":
                            orientatorStatus = statusInterpreter.desc(
                                mensagem.descricao
                            );
                            orientatorColor = statusInterpreter.cor(mensagem.codigo);
                            orientatorSpan = statusInterpreter.span(mensagem.codigo);
                            orientatorCode = mensagem.codigo;
                            break;
                        case "ped":
                            pedStatus = statusInterpreter.desc(mensagem.descricao);
                            pedColor = statusInterpreter.cor(mensagem.codigo);
                            pedSpan = statusInterpreter.span(mensagem.codigo);
                            pedCode = mensagem.codigo;
                            break;
                        case "cr":
                            crStatus = statusInterpreter.desc(mensagem.descricao);
                            crColor = statusInterpreter.cor(mensagem.codigo);
                            crSpan = statusInterpreter.span(mensagem.codigo);
                            crCode = mensagem.codigo;
                            break;
                        case "eqm":
                            examStatus = statusInterpreter.desc(mensagem.descricao);
                            examColor = statusInterpreter.cor(mensagem.codigo);
                            examSpan = statusInterpreter.span(mensagem.codigo);
                            examCode = mensagem.codigo;
                            break;
                        case "eqe":
                            examStatus = statusInterpreter.desc(mensagem.descricao);
                            examColor = statusInterpreter.cor(mensagem.codigo);
                            examSpan = statusInterpreter.span(mensagem.codigo);
                            examCode = mensagem.codigo;
                            break;
                    }
                }
            });

            let item =
            {
                academicCode: student.academicCode,
                name: student.name,
                course: academicLife.course.name,
                period: academicLife.currentPeriod == 0? "-": academicLife.currentPeriod,
                occurrences: academicLife.occurrences,
                couseBreakes: academicLife.courseBreaks.length,
                englishStatus: englishStatus,
                englishColor: englishColor,
                englishSpan: englishSpan,
                englishCode: englishCode,
                creditsStatus: creditsStatus,
                creditsColor: creditsColor,
                creditsSpan: creditsSpan,
                creditsCode: creditsCode,
                orientatorStatus: orientatorStatus,
                orientatorColor: orientatorColor,
                orientatorSpan: orientatorSpan,
                orientatorCode: orientatorCode,
                pedStatus: pedStatus,
                pedColor: pedColor,
                pedSpan: pedSpan,
                pedCode: pedCode,
                crStatus: crStatus,
                crColor: crColor,
                crSpan: crSpan,
                crCode: crCode,
                examStatus: examStatus,
                examColor: examColor,
                examSpan: examSpan,
                examCode: examCode,
            };

            // inserindo um novo objeto
            items.push(item);
        });
        return items;
    }
    return items;
}