/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as storage from '../storage'
import * as types from './mutations_types'

//Verifica se o candidato está com email confirmado
export const ActionIsCandidateEnabled = async (context, email) => {
  //console.log("Email da action is enabled " + email);
  return await services.auth.isCandidateEnabled(email);
}

// realiza a autenticação
export const ActionDoLogin = async (context, payload) => {
  return await services.auth.login(payload); 
}

// seta o token no state da aplicação
export const ActionSetData = ({ commit }, payload) => {
  storage.setHeaderToken(payload.token);
  storage.setLocalData(payload);
  commit(types.SET_DATA, payload);
}

// realiza o logout do usuário
export const ActionSignOut = ({ dispatch }) => {
  storage.setHeaderToken('');
  storage.deleteLocalData();
  localStorage.setItem('theme', false);
  dispatch('ActionSetData', '');
}

// checa o token
export const ActionCheckData = ({ dispatch, state }) => {

  if (state.data) {
    return Promise.resolve(state.data)
  }

  const data = storage.getLocalData()

  if (!data) {
    return Promise.reject(new Error('Token Inválido'))
  }

  dispatch('ActionSetToken', data);
  return dispatch('ActionLoadSession');
}

// Obter novamente os dados do usuário e token
export const ActionLoadSession = ({ dispatch }) => {
  return (async function (resolve, reject) {
    try {
      const data = await services.auth.loadSession();
      dispatch('ActionSetData', data)
      resolve()
    } catch (err) {
      dispatch('ActionSignOut')
      reject(err)
    }
  })
}