/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar os usuários administradores
export const ActionGetGeneralAdmin = (context) => {
    return services.generalAdmin.getGeneralAdmin()
        .then(res => {
            if (res.status === 200) {
                const generalAdmin = res.body;
                context.dispatch('ActionSetGeneralAdmin', generalAdmin);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar os usuários administradores`, error)
        });
}

// realiza a inserção de um usuário administradore
export const ActionPostGeneralAdmin = (context, payload) => {
    return services.generalAdmin.postGeneralAdmin(payload)
        .then(res => {
            if (res.status === 201) {
                const newGeneralAdmin = res.body;
                context.dispatch('ActionAddGeneralAdmin', newGeneralAdmin);
            }
        })
        .catch(error => {
            console.error(`Erro ao inserir um novo usuário administrador`, error)
        });
}

// realiza a remoção de um usuário administrador
export const ActionDeleteGeneralAdmin = (context, payload) => {
    return services.generalAdmin.deleteGeneralAdmin({ id: payload.id })
        .then(res => {
            if (res.status === 200) {
                context.dispatch('ActionRemoveGeneralAdmin', payload);
            }
        })
        .catch(error => {
            console.error(`Erro ao remover o administrador(a) ${payload.name}`, error)
        });
}

// seta os administradores no state da aplicação
export const ActionSetGeneralAdmin = ({ commit }, payload) => {
    commit(types.SET_GENERAL_ADMIN, payload);
}

// adiciona um administrador no state da aplicação
export const ActionAddGeneralAdmin = ({ commit }, payload) => {
    commit(types.ADD_GENERAL_ADMIN, payload);
}

// remove um administrador no state da aplicação
export const ActionRemoveGeneralAdmin = ({ commit }, payload) => {
    commit(types.REMOVE_GENERAL_ADMIN, payload);
}