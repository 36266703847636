import { routes as guidances } from '@/components/faculty/guidances/index'
import { routes as students } from '@/components/student/students/index'
import { routes as student } from '@/components/student/student/index'
import { routes as classes } from '@/components/common/classes/index' 
import { routes as scholarship } from '@/components/admin/scholarship/index'
import { routes as activeUsers } from '@/components/admin/active_users/index'
import { routes as generalAdmin } from '@/components/admin/admin/admin/index'
import { routes as adminOccurrence } from '@/components/admin/occurrence/index' 
import { routes as fundingAgency } from '@/components/admin/funding_agency/index'
import { routes as databaseUpdate } from '@/components/admin/database_update/index'
import { routes as occurrenceType } from '@/components/student/occurrence_type/index'
import { routes as scholarshipQuota } from '@/components/admin/scholarship_quota/index'
import { routes as professors } from '@/components/faculty/professors/index'
import { routes as professor } from '@/components/faculty/professor/index'

import { routes as fileSTable } from '@/components/common/file/index'

import { routes as candidateInfo } from '@/components/candidate/profile/index'
import { routes as editCandidate } from '@/components/candidate/edit_candidate/index'
import { routes as enrollmentPeriod } from '@/components/candidate/enrollment_period/index'
import { routes as enrollmentRequest } from '@/components/candidate/enrollment_request/index'
import { routes as candidateEnrollment } from '@/components/candidate/candidate_enrollment/index'
import { routes as opinionEnrollmentRequest } from '@/components/candidate/opinion_enrollment_request/index'
import { routes as opinionEnrollmentRequestGrade } from '@/components/candidate/opinion_enrollment_request_grade/index'
import { routes as facultyOpinion } from '@/components/faculty/faculty_opinion/index'

import { routes as editors } from '@/components/technical_report/editors/index'
import { routes as technicalReports } from '@/components/technical_report/technical_reports/index'


export default [
    {
        path: '/home',
        name: 'home',
        meta: {
            requiresAuth: true,
            authorizedUsers: ['faculty', 'student', 'staff', 'admin', 'candidate']
        },
        component: () => import('./Home'),
        children: [ students, generalAdmin, student, activeUsers, professors, professor,
            databaseUpdate, occurrenceType, fundingAgency, scholarship, scholarshipQuota, fileSTable, facultyOpinion,
            adminOccurrence, classes, guidances, candidateEnrollment, ...editCandidate, enrollmentPeriod,
            enrollmentRequest, opinionEnrollmentRequest, opinionEnrollmentRequestGrade, candidateInfo, editors,
            ...technicalReports ]
    }
]
