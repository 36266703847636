/**
 *  Arquivo responsável por centralizar todas as funções de storage da aplicação
 */
import { setBearerToken } from '@/http/index'

export const setHeaderToken = data => setBearerToken(data);
export const getLocalData = () => JSON.parse(localStorage.getItem('data'));
export const deleteLocalData = () => localStorage.removeItem('data');
export const setLocalData = data => localStorage.setItem('data', JSON.stringify(data));
export const getTheme = () => localStorage.getItem('dark');
export const setTheme = status => localStorage.setItem('dark', status);
