/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_FILES] (state, payload){
        state.files = payload
    },

    [types.ADD_FILE] (state, payload){
        if (payload.constructor === Array){
            payload.map(e => {
                state.files.push(e)
            })
        }
        else {
            state.files.push(payload);
        }
    },

    [types.REMOVE_FILE] (state, payload){
        state.files = state.files.filter(e => e.id != payload);
    }
}