/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
    path: '/usuarios_ativos',
    name: 'ActiveUsers',
    meta: {
        requiresAuth: true,
        authorizedUsers: ['admin']
    },
    component: () => import('./ActiveUsers')

}
