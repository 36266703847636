/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    getGeneralAdmin: { method: 'get', url: 'api/secure/admin/user'},
    postGeneralAdmin: { method: 'post', url: 'api/secure/admin/user'},
    getGeneralAdminById: { method: 'get', url: 'api/secure/admin/user/{id}'},
    deleteGeneralAdmin: { method: 'delete', url: 'api/secure/admin/user/{id}'}
}