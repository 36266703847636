/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default [
    {
        path: '/signUpCandidate',
        name: 'SignUpCandidate',
        meta: {
            requiresAuth: false,
            authorizedUsers: []
        },
        component: () => import('./SignUpCandidate')
    }
]
