/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

 export default {
    loadSession: { method: 'get', url: 'api/secure/load_session' },
    postEnrollmentRequest: { method: 'post', url: 'api/secure/common/enrollment_request' },
    getEnrollmentRequest: { method: 'get', url: 'api/secure/common/enrollment_request/{id}' },
    getEnrollmentPeriod: { method: 'get', url: 'api/secure/common/enrollment_period/current' },
    getCandidate: { method: 'get', url: 'api/secure/candidate/{username}' },
    getSelectedClassesEnrollment: { method: 'get', url: 'api/secure/common/enrollment_request/candidate/{id}' },
}