/**
 *      Arquivo responsável por concentrar todas as rotas das páginas
 *          do grupo auth
 */
 export default 
    {
        path: '/conceito_solicitacao_matricula/:username',
        name: 'OpinionEnrollmentRequest',
        meta: { 
            requiresAuth: true,
            authorizedUsers: ['admin', 'staff', 'faculty'] 
        },
        component: () => import('./OpinionEnrollmentRequest')
    }
