/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    putFundingAgency: { method: 'put', url: 'api/secure/student/funding_agency' },
    getFundingAgencies: { method: 'get', url: 'api/secure/student/funding_agency' },
    postFundingAgency: { method: 'post', url: 'api/secure/student/funding_agency' },
    getFundingAgency: { method: 'get', url: 'api/secure/student/funding_agency/{id}' },
    delFundingAgency: { method: 'delete', url: 'api/secure/student/funding_agency/{id}' },
    getFundingAgenciesActives: { method: 'get', url: 'api/secure/student/funding_agency/actives' }
}