import * as types from './mutations_types'

export default {
    [types.SET_PROFESSOR_GUIDANCES] (state, payload){
        state.guidances = payload
    },

    [types.SET_PROFESSOR_COGUIDANCES] (state, payload){
        state.coGuidances = payload
    }
}