/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca de agencias de fomento
export const ActionGetFundingAgencies = (context) => {
    return services.fundingAgency.getFundingAgencies()
        .then(res => {
            if (res.status === 200) {
                const fundingAgencies = res.body;
                context.dispatch('ActionSetFundingAgencies', fundingAgencies);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as agências de fomento`, error)
        });
}

// realiza a inserção de nova agência
export const ActionPostFundingAgency = (context, payload) => {
    return services.fundingAgency.postFundingAgency(payload)
        .then(res => {
            if(res.status == 201) {
                const fundingAgency = res.body;
                context.dispatch('ActionAddFundingAgency', fundingAgency);
            }
        })
        .catch(error => {
            console.error('Erro ao salvar a agência de fomento', error);
        });
}

// realiza a alteração de uma agência
export const ActionPutFundingAgency = (context, payload) => {
    return services.fundingAgency.putFundingAgency(payload)
        .then(res => {
            if(res.status == 200) {
                context.dispatch('ActionGetFundingAgencies');
            }
        })
        .catch(error => {
            console.error('Erro ao alterar a agência de fomento', error);
        });
}

// realiza a alteração de uma agência
export const ActionDelFundingAgency = (context, payload) => {
    return services.fundingAgency.delFundingAgency({ id: payload })
        .then(res => {
            if(res.status == 200) {
                context.dispatch('ActionRemoveFundingAgency', payload);
            }
        })
        .catch(error => {
            console.error('Erro ao remover agência de fomento', error);
        });
}

// seta as agencias no state da aplicação
export const ActionSetFundingAgencies = ({ commit }, payload) => {
  commit(types.SET_FUNDING_AGENCIES, payload);
}

// adicionar uma agência no state da módulo
export const ActionAddFundingAgency = ({ commit }, payload) => {
    commit(types.ADD_FUNDING_AGENCY, payload);
}

// Remover uma agência no state do módulo
export const ActionRemoveFundingAgency = ( { commit }, payload) => {
   commit(types.RM_FUNDING_AGENCY, payload);
}