/**
 * Arquivo responsável por analisar as mensagens oriundas do back-end
 */

// Objeto com os status do código da mensagem
const STATUS = {
  OK: 0,
  OK_2: 1,
  INFORMACAO: 2,
  INFORMACAO_2: 3,
  ALERTA: 4,
  ALERTA_2: 5,
  PROBLEMA: 6,
  PROBLEMA_2: 7,
  OPCIONAL: 8,
  OPCIONAL_2: 9,
  ERRO: 10,
  ERRO_2: 11,  
}

const TYPE = {
  CR: 'cr',
  CREDITOS: 'creditos',
  EQM: 'eqm',
  EQG: 'eqg',
  EQE: 'eqe',
  ORIENTACAO: 'orientacao',
  PED: 'ped',
  PROFICIENCIA: 'proficiencia'
}

// Interpretador de status
export const statusInterpreter = {
  cor: (status) => {
    switch (status) {
      case STATUS.OK:
        return 'green lighten-2'
      case STATUS.OK_2:
        return 'green lighten-2'
      case STATUS.ALERTA:
        return 'orange lighten-2'
      case STATUS.ALERTA_2:
        return 'orange lighten-2'
      case STATUS.PROBLEMA:
        return 'red accent-2'
      case STATUS.PROBLEMA_2:
        return 'red accent-2'
      case STATUS.INFORMACAO:
        return 'yellow lighten-1'
      case STATUS.INFORMACAO_2:
        return 'yellow lighten-1'
      case STATUS.ERRO:
        return 'grey'
      case STATUS.ERRO_2:
        return 'grey'
      case STATUS.OPCIONAL:
        return 'yellow lighten-1'
      case STATUS.OPCIONAL_2:
        return 'yellow lighten-1'
      default:
        return 'purple lighten-2'
    }
  },

  desc: (status) => {
    return status;
  },

  title: (status) => {
    switch (status) {
      case TYPE.CR:
        return 'CR'
      case TYPE.CREDITOS:
        return 'Créditos'
      case TYPE.ORIENTACAO:
        return 'Orientação'
      case TYPE.PROFICIENCIA:
        return 'Inglês'
      case TYPE.PED:
        return 'PED'
      case TYPE.EQM:
        return 'EQM'
      case TYPE.EQG:
        return 'EQG'
      case TYPE.EQE:
        return 'EQE'
    }
  },

  span: (status) => {
    switch (status) {
      case STATUS.OK:
        return 'done'
      case STATUS.OK_2:
        return 'done'
      case STATUS.ALERTA:
        return 'priority_high'
      case STATUS.ALERTA_2:
        return 'priority_high'
      case STATUS.PROBLEMA:
        return 'clear'
      case STATUS.PROBLEMA_2:
        return 'clear'
      case STATUS.INFORMACAO:
        return 'code'
      case STATUS.INFORMACAO_2:
        return 'code'
      case STATUS.ERRO:
        return 'E'
      case STATUS.ERRO_2:
        return 'E'
      case STATUS.OPCIONAL:
        return 'chat_bubble_outline'
      case STATUS.OPCIONAL_2:
        return 'chat_bubble_outline'
      default:
        return '?'
    }
  }
}


