/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca pelos periodos de matrícula ativos
export const ActionGetEnrollmentPeriod = (context) => {
    return services.enrollmentPeriod.getEnrollmentPeriod()
        .then(res => {
            if (res.status === 200) {
                const enrollmentPeriod = res.body;
                context.dispatch('ActionSetEnrollmentPeriod', enrollmentPeriod);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar períodos de matricula`, error)
        });
}

// realiza a busca pelos periodos de matrícula ativos
export const ActionPostEnrollmentPeriod = (context, payload) => {
    return services.enrollmentPeriod.postEnrollmentPeriod(payload)
        .then(res => {
            if (res.status === 201) {
                const enrollmentPeriod = res.body;
                context.dispatch('ActionADDEnrollmentPeriod', enrollmentPeriod);
            }
        });
}


// realiza a atualização do período de matricula
export const ActionPutEnrollmentPeriod = (context, payload) => {
    return services.enrollmentPeriod.putEnrollmentPeriod(payload)
        .then(res => {
            if (res.status === 200) {
                const enrollmentPeriod = res.body;
                context.dispatch('ActionRemoveEnrollmentPeriod', enrollmentPeriod.id);
                context.dispatch('ActionADDEnrollmentPeriod', enrollmentPeriod);
            }
        });
}

// realiza a remoção de periodo de matrícula
export const ActionDeleteEnrollmentPeriod = (context, payload) => {
    return services.enrollmentPeriod.delEnrollmentPeriodByID({ id: payload })
        .then(res => {
            if (res.status === 200) {
                context.dispatch('ActionRemoveEnrollmentPeriod', payload);
            }
        })
        .catch(e => {
            console.error(e);
        });
}

// realiza a periodo de matriculas no state
export const ActionSetEnrollmentPeriod = ({commit }, payload) => {
    commit(types.SET_ENROLLMENT_PERIOD, payload);
}

// adicionar a período de matriculas no state da aplicação
export const ActionADDEnrollmentPeriod = ({ commit }, payload) => {
    commit(types.ADD_ENROLLMENT_PERIOD, payload);
}

// remover o período de matricula no state da aplicação
export const ActionRemoveEnrollmentPeriod = ({ commit }, payload) => {
    commit(types.REMOVE_ENROLLMENT_PERIOD, payload);
}