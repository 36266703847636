/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_ENROLLMENT_PERIOD] (state, payload){
        state.enrollmentPeriod = payload
    },

    [types.SET_SELECTED_CLASSES_ENROLLMENT] (state, payload){
        state.selectedClassesEnrollment = payload
    },

    [types.ADD_SELECTED_CLASSES_ENROLLMENT] (state, payload){
        state.selectedClassesEnrollment.push(payload);
    },

    [types.SET_CANDIDATE] (state, payload){
        state.candidate = payload;
    }
}