/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_OCCURRENCE_TYPE] (state, payload){
        state.occurrenceType = payload
    },

    [types.SET_OCCURRENCE_TYPES] (state, payload){
        state.occurrenceTypes = payload
    }
}