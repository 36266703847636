/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_ACADEMIC_LIFES](state, payload) {
        payload.sort(function (a, b) {
            if (a.student > b.student) {
                return 1;
            }
            if (a.student < b.student) {
                return -1;
            }
            return 0;
        });
        state.academicLifes = payload
    }
}