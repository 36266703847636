import { routes as auth } from '@/pages/auth/index'
import { routes as home } from '@/pages/home/index'
import { routes as signUpCandidate } from '@/pages/signup_candidate/index'
import { routes as technicalReport } from '@/pages/technical_reports/index'


export default [
    ...auth,
    ...home,
    ...signUpCandidate,
    ...technicalReport,
]
