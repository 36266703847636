/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca os usuários ativos
export const ActionGetActiveUsers = (context) => {
    return services.activeUsers.getActiveUsers()
        .then(res => {
            if (res.status === 200) {
                const users = res.body;
                context.dispatch('ActionSetActiveUsers', users);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar os usuários ativos`, error)
        });
}


// realiza a inserção de usuário ativo
export const ActionSetActiveUser = (context, payload) => {
    return services.activeUsers.setActiveUser(payload);
}

// realiza a remoção de usuário
export const ActionDelActiveUser = (context, payload) => {
    return services.activeUsers.delActiveUser({ username: payload })
        .then(res =>{
            console.log(res);
        });
}

// seta as convalidações no state da aplicação
export const ActionSetActiveUsers = ({ commit }, payload) => {
  commit(types.SET_ACTIVE_USERS, payload);
}