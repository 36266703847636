/**
 *      Arquivo responsável por concentrar todas as rotas das páginas
 *          do grupo auth
 */
export default
    {
        path: '/inscricao',
        name: 'CandidateEnrollment',
        meta: {
            requiresAuth: true,
            authorizedUsers: ['candidate']
        },
        component: () => import('./CandidateEnrollment')
    }
