/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar os tipos de ocorrências
export const ActionGetOccurrenceType = (context) => {
    return services.occurrenceType.getOccurrenceType()
        .then(res => {
            if (res.status === 200) {
                const occurrenceType = res.body;
                context.dispatch('ActionSetOccurrenceType', occurrenceType);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar os tipos de ocorrências do sistema`, error)
        });
}


// realiza a buscar os tipos de ocorrências ativas
export const ActionGetOccurrenceTypeActives = (context) => {
    return services.occurrenceType.getOccurrenceTypeActives()
        .then(res => {
            if (res.status === 200) {
                const occurrenceType = res.body;
                context.dispatch('ActionSetOccurrenceType', occurrenceType);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar os tipos de ocorrências do sistema`, error)
        });
}

// realiza a buscar os tipos de ocorrências
export const ActionGetOccurrenceTypes = (context) => {
    return services.occurrenceType.getOccurrenceTypes()
        .then(res => {
            if (res.status === 200) {
                const occurrenceTypes = res.body;
                context.dispatch('ActionSetOccurrenceTypes', occurrenceTypes);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar os tipos de ocorrências do sistema`, error)
        });
}

// realiza a inserção de novo tipo de ocorrência
export const ActionPostOccurrenceType = (context, payload) => {
    return services.occurrenceType.postOccurrenceTypes(payload)
        .then(res => {
            if (res.status === 201)
                context.dispatch('ActionGetOccurrenceType');
        })
        .catch(error => {
            console.error(`Erro ao buscar os tipos de ocorrências do sistema`, error)
        });
}

// realiza a inserção de novo tipo de ocorrência
export const ActionPutOccurrenceType = (context, payload) => {
    return services.occurrenceType.putOccurrenceType(payload)
        .then(res => {
            if (res.status === 200)
                context.dispatch('ActionGetOccurrenceType');
        })
        .catch(error => {
            console.error(`Erro ao atualizar o tipo de ocorrência dentro do sistema`, error)
        });
}

// realiza a remoção de um tipo de ocorrência
export const ActionDelOccurrenceType = (context, payload) => {
    return services.occurrenceType.delOccurrenceTypes({ id: payload })
        .then(res => {
            if (res.status === 200)
                context.dispatch('ActionGetOccurrenceType');
        })
        .catch(error => {
            console.error(`Erro ao buscar os tipos de ocorrências do sistema`, error)
        });
}

// seta as atualizações no state da aplicação
export const ActionSetOccurrenceType = ({ commit }, payload) => {
    commit(types.SET_OCCURRENCE_TYPE, payload);
}

// seta as atualizações no state da aplicação
export const ActionSetOccurrenceTypes = ({ commit }, payload) => {
    commit(types.SET_OCCURRENCE_TYPES, payload);
}