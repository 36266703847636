import services from '@/http' 
import * as types from './mutations_types'


// =============================================== REPORTS =============================================
export const ActionGetTechnicalReports = (context, payload) => {
   return services.technicalReports.getTechnicalReports({ username: payload })
    .then(res => {
      if (res.status == 200) {
        const technicalReports = res.body;
        context.dispatch('ActionSETTechnicalReports', technicalReports);
      }
    })
    .catch(error => {
      console.error(`Erro ao buscar a lista de editores!`, error)
      return error;
    }); 
}

export const ActionPostTechnicalReport = (context, payload) => {
   return services.technicalReports.postTechnicalReport(payload)
    .then(res => {
      if(res.status === 201){
        const technicalReport = res.body;
        context.dispatch('ActionADDTechnicalReports', technicalReport);
      }
    }) 
}

export const ActionGetTechnicalReportDetails = (context, payload) => {
  return services.technicalReports.getTechnicalReportById({ id: payload })
   .then(res => {
     if (res.status == 200) {
       const technicalReportDetails = res.body;
       context.dispatch('ActionSetTechnicalReportDetails', technicalReportDetails);
     }
   })
   .catch(error => {
     console.error(`Erro ao buscar relatório técnico`, error)
     return error;
   }); 
}


export const ActionPutTechnicalReport = (context, payload) => {
  return services.technicalReports.putTechnicalReport(payload)
   .then(res => {
     if(res.status === 200){
       const technicalReport = res.body;
       context.dispatch('ActionUPDATETechnicalReports', technicalReport);
     }
   }) 
}


export const ActionDelTechnicalReport = (context, payload) => {
  return services.technicalReports.delTechnicalReportById({ id: payload })
   .then(res => {
     if(res.status === 200){
       context.dispatch('ActionUPDATETechnicalReports', payload);
     }
   }) 
}

export const ActionDELETETechnicalReport = ({ commit }, payload) => {
  commit(types.DELETE_TECHNICAL_REPORT, payload);
}

export const ActionUPDATETechnicalReports = ({ commit }, payload) => {
  commit(types.UPDATE_TECHNICAL_REPORT, payload);
}

export const ActionSETTechnicalReports = ({ commit }, payload) => {
  commit(types.SET_TECHNICAL_REPORTS, payload);
}

export const ActionADDTechnicalReports = ({ commit }, payload) => {
  commit(types.ADD_TECHNICAL_REPORT, payload);
}

export const ActionSetTechnicalReportDetails = ({ commit }, payload) => {
  commit(types.SET_TECHNICAL_REPORT_DETAILS, payload);
}


// =============================================== STATUS =============================================
export const ActionGetTechnicalReportStatusByTechnicalReportId = (context, payload) => {
  return services.technicalReports.getTechnicalReportStatusByTechnicalReportId({ id: payload })
   .then(res => {
     if (res.status == 200) {
       const status = res.body;
       context.dispatch('ActionSETTechnicalReportStatus', status);
       return status;
     }
   })
   .catch(error => {
     console.error(`Erro ao buscar relatório técnico`, error)
     return error;
   }); 
}

export const ActionGetPossibleStatuses = () => {
  return services.technicalReports.getPossibleStatuses();
}

export const ActionPostTechnicalReportStatus = (context, payload) => {
  return services.technicalReports.postTechnicalReportStatus(payload)
   .then(res => {
     if(res.status === 201){
       const status = res.body;
       context.dispatch('ActionADDTechnicalReportStatus', status);
     }
   }) 
}

export const ActionDeleteTechnicalReportStatus = (context, payload) => {
  return services.technicalReports.delTechnicalReportStatus({ id: payload })
   .then(res => {
     if(res.status === 200){
       context.dispatch('ActionADDTechnicalReportStatus', payload);
     }
   }) 
}

export const ActionSETTechnicalReportStatus = ({ commit }, payload) => {
  commit(types.SET_TECHNICAL_REPORT_DETAILS_STATUS, payload);
}

export const ActionADDTechnicalReportStatus = ({ commit }, payload) => {
  commit(types.ADD_TECHNICAL_REPORT_DETAILS_STATUS, payload);
}

export const ActionREMOVETechnicalReportStatus = ({ commit }, payload) => {
  commit(types.REMOVE_TECHNICAL_REPORT_DETAILS_STATUS, payload);
}


// =============================================== COMMENTS =============================================
export const ActionGetTechnicalReportCommentsByTechnicalReportId = (context, payload) => {
  return services.technicalReports.getTechnicalReportCommentsByTechnicalReportId({ id: payload })
   .then(res => {
     if (res.status == 200) {
       const comments = res.body;
       context.dispatch('ActionSETTechnicalReportComments', comments);
     }
   })
   .catch(error => {
     console.error(`Erro ao buscar relatório técnico`, error)
     return error;
   }); 
}

export const ActionPostTechnicalReportComment = (context, payload) => {
  return services.technicalReports.postTechnicalReportComment(payload)
   .then(res => {
     if(res.status === 201){
       const comment = res.body;
       context.dispatch('ActionADDTechnicalReportComment', comment);
     }
   }) 
}

export const ActionDeleteTechnicalReportComment = (context, payload) => {
  return services.technicalReports.delTechnicalReportCommentsById({ id: payload })
   .then(res => {
     if(res.status === 200){
       context.dispatch('ActionREMOVETechnicalReportComment', payload);
     }
   }) 
}

export const ActionSETTechnicalReportComments = ({ commit }, payload) => {
  commit(types.SET_TECHNICAL_REPORT_DETAILS_COMMENTS, payload);
}

export const ActionADDTechnicalReportComment = ({ commit }, payload) => {
  commit(types.ADD_TECHNICAL_REPORT_DETAILS_COMMENT, payload);
}

export const ActionREMOVETechnicalReportComment = ({ commit }, payload) => {
  commit(types.REMOVE_TECHNICAL_REPORT_DETAILS_COMMENT, payload);
}