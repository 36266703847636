/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca do candidato
export const ActionGetCandidate = (context, payload) => {
    return services.candidateEnrollment.getCandidate({ username: payload })
        .then(res => {
            if (res.status === 200) {
                const candidate = res.body;
                context.dispatch('ActionSetCandidate', candidate);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar o candidato`, error)
        });
}

// realiza a busca do candidato
export const ActionGetCandidateInfo = (context, payload) => {
    return services.candidateEnrollment.getCandidate({ username: payload })
}

// realiza a busca os usuários ativos
export const ActionGetEnrollmentPeriod = (context) => {
    return services.candidateEnrollment.getEnrollmentPeriod()
        .then(res => {
            if (res.status === 200) {
                const enrollmentPeriod = res.body;
                context.dispatch('ActionSetEnrollmentPeriod', enrollmentPeriod);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar os período de matriculas`, error)
        });
}


// realiza a busca de solicitações de matrícula do candidato
export const ActionGetEnrollmentRequest = (context, payload) => {
    return services.candidateEnrollment.getSelectedClassesEnrollment({ id: payload })
        .then(res => {
            if (res.status === 200) {
                const enrollmentRequest = res.body;
                context.dispatch('ActionSetSelectedClassesEnrollment', enrollmentRequest);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar os solicitações de matriculas`, error)
        });
}


// realiza a busca de uma solicitação específica do candidato por ID
export const ActionGetEnrollmentRequestById = (context, payload) => {
    return services.candidateEnrollment.getEnrollmentRequest({ id: payload });
}


// realiza um post de solicitações de matrícula do candidato
export const ActionPostEnrollmentRequest = (context, payload) => {
    return services.candidateEnrollment.postEnrollmentRequest(payload)
        .then(res => {
            if (res.status === 201) {
                context.dispatch('ActionSetSelectedClassesEnrollment', res.body);
            }
        })
        .catch(error => {
            console.error(`Erro ao solicitar matricula!`, error)
        });
}


// realiza a inserção de turmas no state
export const ActionSetEnrollmentPeriod = ({ commit }, payload) => {
    commit(types.SET_ENROLLMENT_PERIOD, payload);
}


// seta as opções de matricula do candidato no state da aplicação
export const ActionSetSelectedClassesEnrollment = ({ commit }, payload) => {
    commit(types.SET_SELECTED_CLASSES_ENROLLMENT, payload);
}

// adicionar a solicitação no state da aplicação
export const ActionADDSelectedClassesEnrollment = ({ commit }, payload) => {
    commit(types.ADD_SELECTED_CLASSES_ENROLLMENT, payload);
}

// adicionar o candidato no state da aplicação
export const ActionSetCandidate = ({ commit }, payload) => {
    commit(types.SET_CANDIDATE, payload);
}