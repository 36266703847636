/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


export const ActionGetPublicTechnicalReportsYears = (context, payload) => {
    return services.publicTechnicalReportsYears.getPublicTechnicalReportsYears({})
        .then(res => {
            if (res.status === 200) {
                // const subjects = res.data;
                const subjects = res.body;
                console.log("services.reportState.getReports({})", res, payload);
                context.dispatch('ActionSetPublicTechnicalReportsYears', subjects);
            }
        })
        .catch(error => {
            console.error('Erro ao buscar documentos', error)
        });
}


export const ActionSetPublicTechnicalReportsYears = ({ commit }, payload) => {
    commit(types.SET_PUBLIC_TECHNICAL_REPORTS_YEARS, payload);
    // console.log("payload", payload);
}
