/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

// import { downloadProgress } from '@/components/admin/occurrence/store/actions/'

export default {
    getAdminOccurrences: { method: 'get', url: 'api/secure/student/occurrence' },
    putAdminOccurrences: { method: 'put', url: 'api/secure/student/occurrence' },
    postAdminOccurrences: { method: 'post', url: 'api/secure/student/occurrence' },
    getAdminOccurrenceTeste: { method: 'get', url: 'api/secure/student/occurrence' },
    getAdminOccurrence: { method: 'get', url: 'api/secure/student/occurrence/{id}' },
    delAdminOccurrences: { method: 'delete', url: 'api/secure/student/occurrence/{id}' },
}
