/**
 *      Arquivo responsável por configurar o vuex
 *  da aplicação
 */
import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import VueMathjax from 'vue-mathjax'
Vue.use(VueMathjax)
Vue.use(Vuex);

export default new Vuex.Store({ modules });