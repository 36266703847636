/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
 import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
 import * as types from './mutations_types'
 
 
 // realiza a buscar pelo professor
 export const ActionGetOpinion = (context, payload) => {
    // console.log("Dentro da get opinion", payload);
     return services.facultyOpinion.getOpinion({academicCode: payload})
         .then(res => {
            // console.log("Dentro do 200");
             if (res.status === 200) {
                 const opinion = res.data;
              //   console.log("opnion", opinion);
                 context.dispatch('ActionSetOpinion', opinion);
               //  console.log("dentro do res status 200");
             }
         })
         .catch(error =>{
             console.error(`Erro ao buscar opinion`, error)
         });
 }
 
 
 // seta o estudante no state da aplicação
 export const ActionSetOpinion = ({ commit }, payload) => {
   commit(types.SET_OPINION, payload);
 }



// /**
//  *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
//  * 
//  */
// import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
// //import * as storage from '../storage'
// import * as types from './mutations_types'


// // realiza a buscar pelos orientandos
// export const ActionGetFacultyGuidance = (context) => {
//     return services.facultyGuidance.getfacultyGuidance()
//         .then(res => {
//             if (res.status === 200) {
//                 const guidances = res.data;
//                 context.dispatch('ActionSetFacultyGuidance', guidances);
//             }
//         })
//         .catch(error =>{
//             console.error(`Erro ao buscar orientandos`, error)
//         });
// }


// // realiza a buscar pelos coorientandos
// export const ActionGetFacultyCoGuidance = (context) => {
//     return services.facultyGuidance.getfacultyCoGuidance()
//         .then(res => {
//             if (res.status === 200) {
//                 const guidances = res.data;
//                 context.dispatch('ActionSetFacultyCoGuidance', guidances);
//             }
//         })
//         .catch(error =>{
//             console.error(`Erro ao buscar coorientandos`, error)
//         });
// }


// // seta as orientações no state da aplicação
// export const ActionSetFacultyGuidance = ({ commit }, payload) => {
//   commit(types.SET_FACULTY_GUIDANCE, payload);
// }

// // seta as orientações no state da aplicação
// export const ActionSetFacultyCoGuidance = ({ commit }, payload) => {
//   commit(types.SET_FACULTY_COGUIDANCE, payload);
// }