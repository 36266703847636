/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
    path: '/turmas',
    name: 'Classes',
    meta: {
        requiresAuth: true,
        authorizedUsers: ['admin', 'faculty', 'staff']
    },
    component: () => import('./Classes')
}
