/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar as atualizações
export const ActionGetOccurrences = (context) => {
    return services.adminOccurrence.getAdminOccurrences()
        .then(res => {
            if (res.status === 200) {
                const occurrences = res.body;
                context.dispatch('ActionSetOccurrences', occurrences);
            }
        })
        .catch(error => {
            console.error(`Erro ao buscar ocorrências`, error)
        });
}


// realiza a inserção de nova ocorrência
export const ActionPostOccurrences = async () => {
    return await services.adminOccurrence.postAdminOccurrences();
}

// seta as atualizações no state da aplicação
export const ActionSetOccurrences = ({ commit }, payload) => {
    commit(types.SET_OCCURRENCES, payload);
}

export function downloadProgress(event) {
    if (event != null) {
       /* try {
            let res = JSON.parse(event.currentTarget.response);
            console.log('res', res);
        } catch {
            console.log('event.srcElement.response', event.srcElement.response)
            console.log('event.currentTarget.response', event.currentTarget.response)
            console.log('event.target.response', event.target.response)
        }*/
    }
}

/*
async function getStream() {

    const res = await fetch('http://localhost:8080/streaming/json');
    const reader = res.body.getReader();

    let result;
    while (!(result = await reader.read()).done) {
        let valor = JSON.stringify(Array.from(new Uint8Array('')));
        console.log(valor)
        console.log('chunk size:', result);
    }
}*/