/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// Realiza a busca do números de alunos de mestrado e doutorado
export const ActionGetTotalMasterStudents = ( context ) => {
    return services.numberStudents.getTotalMasterStudents()
        .then(res =>{
            context.dispatch('ActionSetMasterStudents', res.body);
        })
        .catch(error =>{
            console.error('Erro ao buscar a quantidade de alunos', error);
        });
}

// Realiza a busca do números de alunos de mestrado e doutorado
export const ActionGetTotalDoctoralStudents = ( context ) => {
    return services.numberStudents.getTotalDoctoralStudents()
        .then(res =>{
            context.dispatch('ActionSetDoctoralStudents', res.body);
        })
        .catch(error =>{
            console.error('Erro ao buscar a quantidade de alunos', error);
        });
}

// seta os alunos no state da aplicação
export const ActionSetMasterStudents = ({ commit }, payload) => {
    commit(types.SET_MASTERS_STUDENTS, payload);
}

// seta os alunos no state da aplicação
export const ActionSetDoctoralStudents = ({ commit }, payload) => {
    commit(types.SET_DOCTORATE_STUDENTS, payload);
}