/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_SCHOLARSHIPS] (state, payload){
        state.scholarships = payload
    },

    [types.ADD_SCHOLARSHIP] (state, payload){
        state.scholarships.push(payload);
    },

    [types.DEL_SCHOLARSHIP] (state, payload){
        state.scholarships = state.scholarships.filter(e => e.id != payload);
    },

    [types.UPDATE_SCHOLARSHIP] (state, payload){
        state.scholarships = state.scholarships.filter(e => e.id != payload.id);
        state.scholarships.push(payload);
    },

    [types.REMOVE_SCHOLARSHIP] (state, payload){
        state.scholarships = state.scholarships.filter(e => e.id != payload);
    },
}