/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
//import * as storage from '../storage'
import * as types from './mutations_types'


// realiza a buscar pelo estudante
export const ActionGetStudentAcademicCode = (context, payload) => {
    return services.student.studentInfo({id: payload})
        .then(res => {
            if (res.status === 200) {
                const student = res.data;
                context.dispatch('ActionSetStudent', student);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar estudante`, error)
        });
}


// seta o estudante no state da aplicação
export const ActionSetStudent = ({ commit }, payload) => {
  commit(types.SET_STUDENT, payload);
}