/**
 *  Arquivo responsável por configurar o padrão das requisições
 *    que serão realizadas pelo objeto http
 */
import Vue from 'vue'
import services from './services'
import VueResource from 'vue-resource'
import interceptors from './interceptors'

Vue.use(VueResource)


// Cria a variável http para requisições
const http = Vue.http;

// define o path padrão para as requisições
http.options.root = process.env.VUE_APP_API_URL; 

// define o método que irá interceptar todas as requisições http da aplicação
http.interceptors.push(interceptors);

// método para mapear todas as services (requisições e incluir no VueResource) de maneira dinâmica
Object.keys(services).map(service =>{
  services[service] = Vue.resource('', {}, services[service]);
})

// função para setar no header authorization o token
const setBearerToken = token =>{
  http.headers.common['Authorization'] = token;
  // http.headers.common['Content-Type'] = 'multipart/form-data';
}

// console.log(services);

export { http, setBearerToken };
export default services;