/**
 *      Arquivo responsável por concentrar todas as rotas das páginas
 *          do grupo auth
 */
 export default 
    {
        path: '/periodo_matricula',
        name: 'EnrollmentPeriod',
        meta: { 
            requiresAuth: true,
            authorizedUsers: ['admin', 'candidate', 'staff', 'faculty'] 
        },
        component: () => import('./EnrollmentPeriod')
    }
