/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
    path: '/curso',
    name: 'Course',
    meta: {
        requiresAuth: true,
        authorizedUsers: ['admin', 'staff', 'faculty']
    },
    component: () => import('./Course')

}
