/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_OPINION] (state, payload){
        state.facultyOpinion = payload
    },

    // [types.SET_FACULTY_COGUIDANCE] (state, payload){
    //     state.facultyCoGuidance = payload
    // }
}