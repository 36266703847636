/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
    path: '/solicitacoes_turmas/:username',
    name: 'FacultyOpinion',
    meta: {
        requiresAuth: true,
        authorizedUsers: ['staff', 'faculty', 'admin']
    },
    component: () => import('./FacultyOpinion')
}
