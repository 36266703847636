/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_AVAILABLE_RESOURCES] (state, payload){
        state.availableResources = payload
    },

    [types.ADD_AVAILABLE_RESOURCES] (state, payload){
        state.availableResources.push(payload);
    },

    [types.DEL_AVAILABLE_RESOURCES] (state, payload){
        state.availableResources.pull(payload);
    },
}