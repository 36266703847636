export default [
    {
        path: '/relatorios/:username',
        name: 'TechnicalReports',
        meta: {
            requiresAuth: true,
            authorizedUsers: ['admin', 'faculty', 'staff', 'editor']
        },
        component: () => import('./TechnicalReports')
    },
    {
        path: '/relatorio/:id',
        name: 'TechnicalReportDetails',
        meta: {
            requiresAuth: true,
            authorizedUsers: ['admin', 'faculty', 'staff', 'editor', 'student']
        },
        component: () => import('./TechnicalReportDetails')
    },
    {
        path: '/relatorio/atualizar/:id',
        name: 'TechnicalReportUpdate',
        meta: {
            requiresAuth: true,
            authorizedUsers: ['student']
        },
        component: () => import('./TechnicalReportUpdate')
    },    
]