/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_SCHOLARSHIP_QUOTAS] (state, payload){
        state.scholarshipQuotas = payload
    },
    
    [types.ADD_SCHOLARSHIP_QUOTAS] (state, payload){
        payload.map(sq =>{
            state.scholarshipQuotas.push(sq);
        })      
    },

    [types.REMOVE_SCHOLARSHIP_QUOTAS] (state, payload){
        state.scholarshipQuotas = state.scholarshipQuotas.filter(e => e.id != payload); 
    },

    [types.UPDATE_SCHOLARSHIP_QUOTAS] (state, payload){
        state.scholarshipQuotas = state.scholarshipQuotas.filter(e => e.id != payload.id);
        state.scholarshipQuotas.push(payload);     
    },
}