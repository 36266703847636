/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar das disciplinas
export const ActionGetSubjects = (context, payload) => {
    return services.subjects.getSubjects({academic_life_id: payload})
        .then(res => {
            if (res.status === 200) {
                const subjects = res.data.data;
                context.dispatch('ActionSetSubjects', subjects);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as disciplinas`, error)
        });
}

// seta as disciplinas no state da aplicação
export const ActionSetSubjects = ({ commit }, payload) => {
  commit(types.SET_SUBJECTS, payload);
}


// realiza a buscar das disciplinas atuais
export const ActionGetCurrentSubjects = (context, payload) => {
    return services.subjects.getCurrentsSubjects({academic_life_id: payload})
        .then(res => {
            if (res.status === 200) {
                const subjects = res.data;
                context.dispatch('ActionSetCurrentSubjects', subjects);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as disciplinas correntes`, error)
        });
}

// seta as disciplinas atuasi no state da aplicação
export const ActionSetCurrentSubjects = ({ commit }, payload) => {
  commit(types.SET_CURRENT_SUBJECTS, payload);
}