/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
    path: '/agencias_fomento',
    name: 'FundingAgency',
    meta: {
        requiresAuth: true,
        authorizedUsers: ['admin', 'staff']
    },
    component: () => import('./FundingAgency')

}
