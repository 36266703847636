/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_SUBMITTED_DOCUMENTS](state, payload) {
        state.submittedDocuments = []
        state.submittedDocuments = payload

    },
    [types.SET_ALL_PROFESSORS](state, payload) {
        //console.log(state, payload);
        //let document = state.submittedDocuments.splice([state.submittedDocuments.indexOf(payload)],1)
        state.allProfessors = payload
    }
}