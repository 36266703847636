/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
    path: '/atualizacoes',
    name: 'DatabaseUpdate',
    meta: {
        requiresAuth: true,
        authorizedUsers: ['admin']
    },
    component: () => import('./DatabaseUpdate')
}
