/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
    path: '/cotas',
    name: 'ScholarshipQuota',
    meta: {
        requiresAuth: true,
        authorizedUsers: ['admin', 'staff']
    },
    component: () => import('./ScholarshipQuota')

}
