/**
 *      Arquivo responsável por concentrar todas as rotas das páginas
 *          do grupo auth
 */
export default [
        {
            path: '/',
            name: 'login',
            meta: { onlyAnonymous: true },
            component: () => import('./Login')
        }
    ]
