/**
 *  Middleware para verificar token nas requisições
 */
//import store from '../store'
import * as storage from '../pages/auth/storage'

export default async (to, from, next) => {

  const auth = await storage.getLocalData();
  const authenticated = auth ? true : false;
  const authorizedUsers = to.meta.authorizedUsers;

  /* console.log('authenticated?', authenticated);
  console.log('authorizedUsers', authorizedUsers);
  console.log('to', to);
  console.log('from', from) */

  // se a página sofreu refresh
  if (from.name === null && auth)
    storage.setHeaderToken(auth.token);

  // Se o usuário está autenticado
  if (authenticated) {

    // Se está indo para login e tem autenticação
    if (to.name === 'login' || to.name === null) {
      next({ path: '/home' })
    }

    else {
      const roles = auth.user.roles;
      const authorities = roles.filter(role => {
        return authorizedUsers.includes(role);
      });

      // Se está autorizado
      if (authorities) {
        switch (to.name) {
          case 'SignUpCandidate':
            next({ name: 'home' })
            break;
          case 'login':
            next({ name: 'home' })
            break;
          case '':
            next({ name: 'home' })
            break;
          default:
            next();
            break;
        }
      }
      // se não está autorizado
      else {
        next({ name: 'home' })
      }

    }
  }
  // Se o usuário não possui autenticação
  else {
    switch (to.name) {
      case 'login':
        next();
        break;
      case 'SignUpCandidate':
        next();
        break;
      case 'PublicTechnicalReport':
        next();
        break;
      case 'TechnicalReports':
        next();
        break;
      case 'InstructionsAuthors':
        next();
        break;
      case 'InstructionsAuthorsPFG':
        next();
        break;
      default:
        next({ name: 'login' })
        break;
    }
  }
}