/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca de cotas
export const ActionGetScholarshipQuotas = (context) => {
    return services.scholarshipQuota.getScholarshipQuota()
        .then(res => {
            if (res.status === 200) {
                const scholarshipQuotas = res.body;
                context.dispatch('ActionSetScholarshipQuotas', scholarshipQuotas);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as cotas`, error)
        });
}


// realiza a busca de cotas ligadas a uma agência
export const ActionGetScholarshipQuotaByFundingAgency = (context, payload) => {
    return services.scholarshipQuota.getScholarshipQuotaByFundingAgency({ id: payload });
}

// seta as cotas no state da aplicação
export const ActionSetScholarshipQuotas = ({ commit }, payload) => {
  commit(types.SET_SCHOLARSHIP_QUOTAS, payload);
}

// realiza a inserção de várias cotas ao mesmo tempo
export const ActionPostScholarshipQuotasByQuantity = (context, payload) => {
    return services.scholarshipQuota.postScholarshipQuotaByQuantity({quantity: payload.quantity}, payload.body)
        .then(res => {
            if (res.status === 201) {
                const scholarshipQuotas = res.body;
                context.dispatch('ActionAddScholarshipQuota', scholarshipQuotas);
            }
        })
        .catch(error =>{
            console.error(`Erro ao inserir uma cota`, error)
        });
}

// adiciona uma nova cota no state da aplicação
export const ActionAddScholarshipQuota = ({ commit }, payload) => {
    commit(types.ADD_SCHOLARSHIP_QUOTAS, payload);
}

// Remove uma cota específica
export const ActionDelScholarshipQuota = (context, payload) => {
    return services.scholarshipQuota.delScholarshipQuota({ id: payload })
        .then(res => {
            if(res.status == 200)
                context.dispatch('ActionRemoveScholarshipQuota', payload);
        })
        .catch(error =>{
            console.error('Erro ao remover uma cota', error);
        })
}

// Remove uma cota do state da aplicação
export const ActionRemoveScholarshipQuota = ({ commit }, payload) => {
    commit(types.REMOVE_SCHOLARSHIP_QUOTAS, payload);
}

// Atualiza uma cota
export const ActionPutScholarshipQuota = (context, payload) => {
    return services.scholarshipQuota.putScholarshipQuota(payload)
    .then(res => {
        if(res.status == 200)
            context.dispatch('ActionUpdateScholarshipQuota', res.body);
    })
}

// Altera o status de uma cota
export const ActionPutStatusScholarshipQuota = (context, payload) => {
    return services.scholarshipQuota.putStatusScholarshipQuota({id: payload}, null)
    .then(res => {
        if(res.status == 200){
            context.dispatch('ActionUpdateScholarshipQuota', res.body);
        }
    })
}

// atualiza uma cota no state da aplicação
export const ActionUpdateScholarshipQuota = ({ commit }, payload) => {
    commit(types.UPDATE_SCHOLARSHIP_QUOTAS, payload);
}