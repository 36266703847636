/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

export default {
    putScholarship: { method: 'put', url: 'api/secure/student/scholarship' },
    getScholarships: { method: 'get', url: 'api/secure/student/scholarship' },
    postScholarship: { method: 'post', url: 'api/secure/student/scholarship' },
    getScholarship: { method: 'get', url: 'api/secure/student/scholarship/{id}' },
    delScholarship: { method: 'delete', url: 'api/secure/student/scholarship/{id}' },
    deactivateScholarship: { method: 'put', url: 'api/secure/student/scholarship/deactivate/{id}' },
    getScholarshipsByQuotaId: { method: 'get', url: 'api/secure/student/scholarship/scholarship_quota/{id}' },
}