/**
 *      Arquivo responsável por guardar todos os end-point que serão requisitados
 *          pelo módulo
 */

const MIN_3 = (60 * 1000 * 3);
const MIN_1 = (60 * 1000);

export default {
    getStudents: { method: 'get', url: 'api/secure/student/student/actives', timeout: MIN_3},    
    getActivesStudents: { method: 'get', url: 'api/secure/student/student/all_actives', timeout: MIN_3}, 
    getStudentsByName: { method: 'get', url: 'api/secure/student/student/name/{name}', timeout: MIN_1}
}