/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar das convalidações
export const ActionGetSubjectsValidations = (context, payload) => {

    return services.subjectsValidations.getSubjectsValidations({academic_life_id: payload})
        .then(res => {
            if (res.status === 200) {
                const subjects = res.data;
                context.dispatch('ActionSetSubjectsValidations', subjects);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar convalidações`, error)
        });
}

// seta as convalidações no state da aplicação
export const ActionSetSubjectsValidations = ({ commit }, payload) => {
  commit(types.SET_SUBJECTS_VALIDATIONS, payload);
}