import * as types from './mutations_types'

export default {
    [types.SET_EDITORS](state, payload) {
        state.editors = payload
    },

    [types.ADD_EDITOR](state, payload) {
        if (payload.constructor === Array) {
            payload.map(e => {
                state.editors.push(e)
            })
        }
        else {
            state.editors.push(payload);
        }
    },

    [types.UPDATE_EDITOR](state, payload) {
        state.editors = state.editors.filter(e => e.id != payload.id);
        state.editors.push(payload);
    },

    [types.REMOVE_EDITOR](state, payload) {
        state.editors = state.editors.filter(e => e.id != payload);
    }
}