/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca de turmas por nível, ano e semestre
export const ActionGetSubmittedDocuments = (context, payload ) => {
    return services.facultyEditor.getSubmittedDocuments(payload)
        .then(res => {
            if (res.status === 200) {
                console.log('200-', res.body);
                const response = res.body;
                context.dispatch('ActionSetSubmittedDocuments', response);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as turmas`, error)
        });
}

export const ActionEvaluateDocument = (context, payload ) => {
    return services.facultyEditor.evaluateDocument({pk:payload.pk}, payload.body)
        .then(res => {
            if (res.status === 200) {
                console.log('200-', res, context);
                //const response = res.body;
                // context.dispatch('ActionGetSubmittedDocuments');
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as turmas`, error)
        });
}

export const ActionGetSpecificDocument = (context, payload ) => {
    return services.facultyEditor.getSpecificDocument({documentId:payload.documentId}, payload.body)
        .then(res => {
            if (res.status === 200) {
                console.log('200-', res.body, context);
                // const response = res.body;
                //context.dispatch('ActionSetSubmittedDocuments', response);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as turmas`, error)
        });
}

export const ActionGetAllProfessors = (context, payload) => {
    return services.facultyEditor.getAllProfessors()
    .then(res => {
        if (res.status === 200) {
            console.log('200-', res.body, context, payload);
        }
    })
    .catch(error => {
        console.error("Error detected", error);
    })
}

// realiza a busca de turmas correntes por nível
/*export const ActionGetCurrentClassesByLevel = (context, payload) => {
    return services.classes.getCurrentClassesByLevel({ level: payload })
        .then(res => {
            if (res.status === 200) {
                const classes = res.body;
                context.dispatch('ActionSetClasses', classes);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as turmas`, error)
        });
}*/


// seta as cotas no state da aplicação
export const ActionSetSubmittedDocuments = ({ commit }, payload) => {
  commit(types.SET_SUBMITTED_DOCUMENTS, payload);
}
