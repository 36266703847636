/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca dos recursos da agencia de fomento
export const ActionGetAvailableResources = (context) => {
    return services.availableResource.getAvailableResources()
        .then(res => {
            if (res.status === 200) {
                const availableResources = res.body;
                context.dispatch('ActionSetAvailableResources', availableResources);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar os recursos da agência de fomento`, error)
        });
}

// realiza a inserção de novo recurso da agência de fomento
export const ActionPostAvailableResource = (context, payload) => {  
    payload.body.begin = payload.body.begin + "T00:00:00-03:00"
    payload.body.end = payload.body.end + "T00:00:00-03:00"  
    return services.availableResource.postAvailableResource({id: payload.id}, payload.body);
}

// realiza a inserção de novo recurso da agência de fomento
export const ActionPutAvailableResource = (context, payload) => {
    payload.begin = payload.begin + "T00:00:00-03:00"
    payload.end = payload.end + "T00:00:00-03:00"  
    return services.availableResource.putAvailableResource(payload);
}

export const ActionDelAvailableResource = (context, payload) => {
    return services.availableResource.delAvailableResource({ id: payload});
}

// seta os recursos disponíveis da agencia no state da aplicação
export const ActionSetAvailableResources = ({ commit }, payload) => {
  commit(types.SET_AVAILABLE_RESOURCES, payload);
}

// adicionar um recurso disponível da agência no state da módulo
export const ActionAddAvailableResources = ({ commit }, payload) => {
    commit(types.ADD_AVAILABLE_RESOURCES, payload);
}