/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_FACULTY_PROFILE_PAGE_GUIDANCE] (state, payload){
        state.facultyProfilePageGuidance = payload
    },

    [types.SET_FACULTY_PROFILE_PAGE_COGUIDANCE] (state, payload){
        state.facultyProfilePageCoGuidance = payload
    }
}