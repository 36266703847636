import Vue from 'vue'
import App from './App'
import store from './store/index'
import router from './router/index'
import vuetify from './plugins/vuetify'
import Clipboard from 'v-clipboard'
import 'vuetify/dist/vuetify.min.css'
import { VueMaskDirective } from "v-mask";

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

Vue.directive("mask", VueMaskDirective);
Vue.config.productionTip = false;
Vue.use(Clipboard);

window._Vue = new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')