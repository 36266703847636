/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_SUBJECTS] (state, payload){
        state.subjects = payload
    },
    [types.SET_CURRENT_SUBJECTS] (state, payload){
        state.currentSubjects = payload
    }

}