/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca de matrículas de uma determinada turma
export const ActionGetEnrollmentsByClassId = (context, payload ) => {
    return services.enrollments.getEnrollmentsByClassId({ id: payload })
        .then(res => {
            if (res.status === 200) {
                const enrollments = res.body;
                context.dispatch('ActionSetEnrollments', enrollments);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as turmas`, error)
        });
}


// seta as cotas no state da aplicação
export const ActionSetEnrollments = ({ commit }, payload) => {
  commit(types.SET_ENROLLMENTS, payload);
}
