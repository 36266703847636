/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca de vidas acadêmicas
export const ActionGetAcademicLifes = (context) => {
    return services.academicLife.getAcademicLifes()
        .then(res => {
            if (res.status === 200) {
                const academicLifes = res.body;
                context.dispatch('ActionSetAcademicLifes', academicLifes);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as vidas acadêmicas`, error)
        });
}


// seta as cotas no state da aplicação
export const ActionSetAcademicLifes = ({ commit }, payload) => {
  commit(types.SET_ACADEMIC_LIFES, payload);
}
