/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_ENROLLMENT_PERIOD] (state, payload){
        state.enrollmentPeriod = payload
    },

    [types.ADD_ENROLLMENT_PERIOD] (state, payload){
        state.enrollmentPeriod.push(payload);
    },

    [types.REMOVE_ENROLLMENT_PERIOD] (state, payload){
        state.enrollmentPeriod = state.enrollmentPeriod.filter(e => e.id !== payload);
    }
}