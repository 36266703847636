import * as types from './mutations_types'

export default {
    [types.SET_TECHNICAL_REPORTS](state, payload) {
        state.technicalReports = payload
    },

    [types.ADD_TECHNICAL_REPORT](state, payload) {
        state.technicalReports.push(payload);
    },

    [types.UPDATE_TECHNICAL_REPORT](state, payload) {
        state.technicalReports = state.technicalReports.filter(t => t.id != payload.id);
        state.technicalReports.push(payload);
    },


    [types.SET_TECHNICAL_REPORT_DETAILS](state, payload) {
        state.technicalReportDetails = payload;
    },

    [types.DELETE_TECHNICAL_REPORT](state, payload) {
        state.technicalReportDetails = null;
        state.technicalReports = state.technicalReports.filter(t => t.id != payload.id);
    },

    [types.SET_TECHNICAL_REPORT_DETAILS_COMMENTS](state, payload) {
        state.technicalReportComments = payload;
    },

    [types.ADD_TECHNICAL_REPORT_DETAILS_COMMENT](state, payload) {
        state.technicalReportComments.push(payload);
    },

    [types.REMOVE_TECHNICAL_REPORT_DETAILS_COMMENT](state, payload) {
        state.technicalReportComments = state.technicalReportComments.filter(s => s.id != payload);
    },

    

    [types.SET_TECHNICAL_REPORT_DETAILS_STATUS](state, payload) {
        state.technicalReportStatus = (payload)
    },

    [types.ADD_TECHNICAL_REPORT_DETAILS_STATUS](state, payload) {
        state.technicalReportStatus.push(payload);
    },

    [types.REMOVE_TECHNICAL_REPORT_DETAILS_STATUS](state, payload) {
        state.technicalReportStatus = state.technicalReportStatus.filter(s => s.id != payload);
    },
}