/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca de cursos
export const ActionGetCourses = (context) => {
    return services.course.getCourses()
        .then(res => {
            if (res.status === 200) {
                const courses = res.body;
                context.dispatch('ActionSetCourses', courses);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar os cursos`, error)
        });
}


// realiza a busca de cursos relacionados a uma unidade
export const ActionGetCoursesByCollegeCode = (context, payload) => {
    return services.course.getCoursesByCollegeCode({ code: payload })
        .then(res => {
            if (res.status === 200) {
                const courses = res.body;
                context.dispatch('ActionSetCourses', courses);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar os cursos`, error)
        });
}

// seta os cursos no state da aplicação
export const ActionSetCourses = ({ commit }, payload) => {
  commit(types.SET_COURSES, payload);
}