/**
 *  Arquivo responsável pela interceptação de todas as requisições http
 *      da aplicação
 */
import store from '../store/index'

// retorna uma função com implementação de requisição
export default () => {
     return (res) => { 

        //console.log('req', req);

         // status de token expirado
         if(res.status === 403){
            store.dispatch('auth/ActionSignOut');
            window._Vue.$router.push({ name: 'login', params: { login: {
               status: 498,
               message: "Login expirado. Entre novamente."
            } } });
         } 
     }
}