/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a busca de turmas por nível, ano e semestre
export const ActionGetClassesByLevelAndYearAndSemester = (context, payload ) => {
    return services.classes.getClassesByLevelAndYearAndSemester(payload)
        .then(res => {
            if (res.status === 200) {
                const classes = res.body;
                context.dispatch('ActionSetClasses', classes);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as turmas`, error)
        });
}


// realiza a busca de turmas correntes por nível
export const ActionGetCurrentClassesByLevel = (context, payload) => {
    return services.classes.getCurrentClassesByLevel({ level: payload })
        .then(res => {
            if (res.status === 200) {
                const classes = res.body;
                context.dispatch('ActionSetClasses', classes);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar as turmas`, error)
        });
}


// seta as cotas no state da aplicação
export const ActionSetClasses = ({ commit }, payload) => {
  commit(types.SET_CLASSES, payload);
}
