/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_MASTERS_STUDENTS] (state, payload){
        state.mastersStudents = payload
    },

    [types.SET_DOCTORATE_STUDENTS] (state, payload){
        state.doctoratesStudents = payload
    }
}