/**
 *      Arquivo responsável por concentrar todas as rotas do componente
 */
export default {
    path: '/tipos_ocorrencia',
    name: 'OccurrenceType',
    meta: {
        requiresAuth: true,
        authorizedUsers: ['admin', 'staff']
    },
    component: () => import('./OccurrenceType')
}
