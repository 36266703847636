/**
 *  Arquivo responsável por alterar o estado da aplicação
 */
import * as types from './mutations_types'

export default {
    [types.SET_OPINION_GRADE](state, payload) {
        state.grades = payload;
    },

    [types.ADD_OPINION_GRADE](state, payload) {
        state.grades.push(payload);
    },

    [types.UPDATE_OPINION_GRADE](state, payload) {
        state.grades = state.grades.filter(e => e.id != payload.id);
        state.grades.push(payload);
    },

    [types.REMOVE_OPINION_GRADE](state, payload) {
        state.grades = state.grades.filter(e => e.id != payload);
    },
}