/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */

import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'
import * as storage from '../storage'

// realiza a buscar pelos orientandos
export const ActionPostCandidate = async (context, candidate) => {
  //console.log("canidato da actionpost " + candidate);
  return await services.signUpCandidate.postCandidate(candidate);
}

// seta as orientações no state da aplicação
export const ActionSetCandidate = ({ commit }, payload) => {
  commit(types.SET_CANDIDATE, payload);
  //console.log("salvei o payload no localStorage: " + JSON.stringify(payload));
  storage.setLocalData(payload);
}

// Buscar endereço pelo cep
export const ActionGetAddress = (context, payload) => {
  return services.signUpCandidate.getAddress({ cep: payload });
}

// Buscar instituições de ensino
export const ActionGetColleges = () => {
  return services.signUpCandidate.getColleges();
}

// Buscar os países
export const ActionGetCountries = () => {
  return services.signUpCandidate.getCountries();
}
