/**
 *  Arquivo responsável por gerenciar as actions para alterar o estado da aplicação
 * 
 */
import services from '@/http'  // omite-se o index pois o mesmo já reconhece index.js com default
import * as types from './mutations_types'


// realiza a buscar pelos orientandos
export const ActionGetFacultyProfilePageGuidance = (context, payload) => {
    return services.professor.getfacultyProfilePageGuidance({ academicCode: payload })
        .then(res => {
            if (res.status === 200) {
                const guidances = res.data;
                context.dispatch('ActionSetFacultyProfilePageGuidance', guidances);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar orientandos`, error)
        });
}


// realiza a buscar pelos coorientandos
export const ActionGetFacultyProfilePageCoGuidance = (context, payload) => {
    return services.professor.getfacultyProfilePageCoGuidance({ academicCode: payload })
        .then(res => {
            if (res.status === 200) {
                const guidances = res.data;
                context.dispatch('ActionSetFacultyProfilePageCoGuidance', guidances);
            }
        })
        .catch(error =>{
            console.error(`Erro ao buscar coorientandos`, error)
        });
}


// seta as orientações no state da aplicação
export const ActionSetFacultyProfilePageGuidance = ({ commit }, payload) => {
  commit(types.SET_FACULTY_PROFILE_PAGE_GUIDANCE, payload);
}

// seta as orientações no state da aplicação
export const ActionSetFacultyProfilePageCoGuidance = ({ commit }, payload) => {
  commit(types.SET_FACULTY_PROFILE_PAGE_COGUIDANCE, payload);
}